<template lang="">
  <div>
    <template v-if="$i18n.locale === 'en'">
      <div>
        <div class="banner">
          <div class="container">
            <el-row :gutter="20" class="content-head">
              <el-col :span="24">
                <router-link class="linkTo" to="/careers"><p style="margin-top: 100px; color: #3370FF">Career/</p></router-link>
                <h1>Product Manager</h1>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="container" style="margin-top: 30px;">
          <ApplyModal />
          <el-row :gutter="24" style="margin-top: 40px">
            <el-col :span="18">
              <div class="career-detail">
                <h1>Job Description</h1>
                <p>MosApp is an instant messaging application that uses end-to-end encryption technology. It focuses on information security and protecting user privacy. The encrypted chat server does not save any information, and anyone outside the conversation cannot read or listen to it. You can safely chat and make audio and video calls with your friends and family. MosApp features include encrypted one-on-one and group chats, channel subscriptions, group chats with up to 10,000 people, burn after reading messages, two-way deletion, read receipts, and app lock screens.</p>
                <h1>Benefits</h1>
                <p>Good pay | Perfect attendance incentive | Employee referral incentive | NSSF | Opportunities for promotion | Large teamwork | Modern and comfortable office | Best office location | Public holidays follow labor law of the kingdom (100%) | Annual leave, maternity leave (3 months), paternity, special leave, sick leave, marriage leave, mourning leave...etc.</p>
                <h1>Qualification Requirement</h1>
                <ul class="list-style">
                  <li><p>Full-time bachelor's degree or higher.</p></li>
                  <li><p>2 years of experience in the internet industry, with experience in consumer-facing products and market research.</p></li>
                  <li><p>Strong logical thinking and analytical abilities, adept at discovering and seizing opportunities to drive business development.</p></li>
                  <li><p>Honest character, cheerful and optimistic personality, diligent and proactive in work, and strong sense of responsibility.</p></li>
                </ul>
                <h1>Responsibility</h1>
                <ul class="list-style">
                  <li><p>Responsible for product planning, design work, and conducting market-related product research.</p></li>
                  <li><p>Responsible for coordinating the product development process, tracking and managing progress and quality.</p></li>
                  <li><p>Responsible for providing product proposals, assisting other departments in testing and configuring product solutions, and providing test data.</p></li>
                  <li><p>Organize product issues, develop improvement plans, propose product development and enhancement requirements to the R&D department, create progress schedules, and monitor their implementation.</p></li>
                  <li><p>Collaborate with relevant departments to provide solutions to product issues and provide configuration documents to the operations and technical support department.</p></li>
                  <li><p>Ensure the quality of products before they go live, provide training to relevant departments after product upgrades or the launch of new systems.</p></li>
                  <li><p>Track the entire process of product research, testing, and formal use, promptly identify problems, and propose solutions.</p></li>
                  <li><p>During product upgrades, update related configuration documents, product documentation, and backup plans while managing product versions.</p></li>
                </ul>
                <h1>Contact Info</h1>
                <p>CONTACT NAME : Ms.Camila</p>
                <!-- <p>ADDRESS : 111 SOMERSET ROAD #08-05, 111 SOMERSET SINGAPORE (238164)</p> -->
                <p>EMAIL : mosapp.app@gmail.com</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <h1 style="font-size:20px;">Job Details</h1>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">Employment Type</p>
                  <p>Full-time</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">Vacancy</p>
                  <p>15 Positions</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">Deadline</p>
                  <p>31 Nov 2023</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </template>
    <template v-if="$i18n.locale === 'zh'">
      <div>
        <div class="banner">
          <div class="container">
            <el-row :gutter="20" class="content-head">
              <el-col :span="24">
                <router-link class="linkTo" to="/careers"><p style="margin-top: 100px; color: #3370FF">职业/</p></router-link>
                <h1>产品经理</h1>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="container" style="margin-top: 30px;">
          <ApplyModal />
          <el-row :gutter="24" style="margin-top: 40px">
            <el-col :span="18">
              <div class="career-detail">
                <h1>岗位描述</h1>
                <p>MosApp 是一款使用端到端加密技术的即时通讯应用。它专注于信息安全和保护用户隐私。加密聊天服务器不保存任何信息，对话外的任何人都无法阅读或收听。您可以安全地与朋友和家人进行聊天，并进行音频和视频通话。MosApp 的功能包括加密的一对一和群组聊天，频道订阅，最多可容纳 10,000 人的群组聊天，阅后即焚消息，双向删除，已读回执以及应用程序锁定屏幕。</p>
                <h1>福利待遇</h1>
                <p>Good pay | Perfect attendance incentive | Employee referral incentive | NSSF | Opportunities for promotion | Large teamwork | Modern and comfortable office | Best office location | Public holidays follow labor law of the kingdom (100%) | Annual leave, maternity leave (3 months), paternity, special leave, sick leave, marriage leave, mourning leave...etc.</p>
                <h1>资格要求</h1>
                <ul class="list-style">
                  <li><p>全日制本科及其以上学历。</p></li>
                  <li><p>2年互联网行业工作经验，有c端产品经验，有市场调研经验。</p></li>
                  <li><p>较强的逻辑思维. 分析能力. 善于发现和把握推动业务发展的机会；</p></li>
                  <li><p>人品正直. 性格开朗乐观. 工作勤奋积极主动，责任心强。</p></li>
                </ul>
                <h1>职责</h1>
                <ul class="list-style">
                  <li><p>负责产品的规划. 设计工作；以及市场相关产品调研</p></li>
                  <li><p>负责产品开发过程的协调. 进度质量跟踪和管理；</p></li>
                  <li><p>负责提供产品方案，协助其他部门进行产品方案测试和特殊配置，并提供测试数据；</p></li>
                  <li><p>整理产品问题，制定改进计划，向研发部门提出产品开发及改进需求，制订进度计划，并进行跟踪；</p></li>
                  <li><p>协同相关部门，完成产品问题的解决方案，向运营技术支持部提供配置文档；</p></li>
                  <li><p>把控上线前产品的质量，在产品升级. 新系统上线后，对相关部门人员进行培训；</p></li>
                  <li><p>对产品研发. 试用及正式使用的全过程进行跟踪，及时发现问题并提出解决方案；</p></li>
                  <li><p>在产品升级过程中，同步更新相关的配置文档，产品文档和备份方案，管理产品的版本。</p></li>
                </ul>
                <h1>联系信息</h1>
                <p>联系人姓名 : Ms.Camila</p>
                <!-- <p>地址 : 111 SOMERSET ROAD #08-05, 111 SOMERSET SINGAPORE (238164)</p> -->
                <p>电子邮件 : mosapp.app@gmail.com</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <h1 style="font-size:20px;">职位详情</h1>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">雇佣类型</p>
                  <p>Full-time</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">空缺职位</p>
                  <p>15 Positions</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">截止日期</p>
                  <p>31 Nov 2023</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import ApplyModal from './ApplyModal.vue'

export default {
  components: {
    ApplyModal
  },
  data() {
    return { }
  }
}
</script>
<style lang="less" scoped>
.banner {
  position: relative;
  background-color: #E7EEFF99;
  background-position: center;
  height: auto;
  .container {
    color: #ffffff;
    position: relative;
    .content-head {
      padding-bottom: 40px;
      h1 {
        color: #1F1F1F;
        font-size: 50px;
        font-weight: 700;
      }
      p {
        font-size: 20px;
        font-weight: 400px;
      }
    }
  }
}
.career-detail {
  h1 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  p {
    font-size: 17px;
    margin-bottom: 30px;
  }
}
ul.list-style {
  p {
    margin: 0;
  }
  margin-left: 35px;
  margin-bottom: 30px;
  li {
    list-style-type: inherit;
  }
}
</style>
