/*
 * @Author: Sky
 * @Date: 2024-09-09 13:37:31
 * @LastEditors: Sky
 * @LastEditTime: 2024-09-14 09:38:51
 * @Description: 频道列表相关接口
 */
import { makeApiRequest } from '@/utils/request'
const baseApiUrl = process.env.VUE_APP_IM_API

export function getOfficalTopicPage(param) {
  return makeApiRequest({
    baseURL: baseApiUrl,
    url: '/topic/getOfficalTopicPage',
    method: 'POST',
    data: param
  })
}

export function webIncrViewCount(param) {
  return makeApiRequest({
    baseURL: baseApiUrl,
    url: '/topic/webIncrViewCount',
    method: 'POST',
    data: param
  })
}
export function getTopicComment(param) {
  return makeApiRequest({
    baseURL: baseApiUrl,
    url: '/topic/getTopicComment',
    method: 'POST',
    data: param
  })
}

export function getTopicReply(param) {
  return makeApiRequest({
    baseURL: baseApiUrl,
    url: '/topic/getTopicReply',
    method: 'POST',
    data: param
  })
}
