import { URL_REG } from './regRule'
// 下载文件
export function downloadFile(url, name) {
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
/**
* 获取Assets资源
*/
export function getAssetsFile(url) {
  return require(`@/assets/image/home/${url}`)
}

window.linkClick = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    url = 'https://' + url
  }
  window.location.href = url
}

// json转富文本html
export function generateHtmlFromJson(text, Styles, size = 0, urlJump = true) {
  if (!text) return ''
  // 处理html标签，转为实体，避免显示的时候被解析为标签
  text = text.replace(/</g, '&lt;').replace(/>/g, '&gt;')
  let result = ''
  const classNameList = []
  if (urlJump) {
    classNameList.push('link-text')
  }
  if (!Styles) {
    result = text.replace(URL_REG, `<span class="${classNameList.join(' ')}" data-url="$&" ${urlJump ? 'onclick="linkClick(\'$&\')"' : ''}>$&</span>`)
    return result
  }

  // 防止死循环，后面排序会改变数组造成死循环
  const textStyles = [...Styles]

  // 将所有样式按起始位置排序
  textStyles.sort((a, b) => a.start - b.start)

  // 用于存储文本每个字符的样式
  const styledSegments = []
  for (let i = 0; i < text.length; i++) {
    styledSegments[i] = { index: i, styles: [], text: text[i] }
  }

  // 遍历所有样式并应用到 styledSegments 中
  textStyles.forEach((style) => {
    for (let i = style.start; i < style.end; i++) {
      if (!styledSegments[i]) return
      if (style.style === 'Bold') {
        styledSegments[i].styles.push('font-weight:bold;')
      } else if (style.style === 'Italic') {
        styledSegments[i].styles.push('font-style:italic;')
      } else if (style.style === 'Underline') {
        styledSegments[i].styles.push('text-decoration:underline;')
      } else if (style.style === 'Link') {
        styledSegments[i].styles.push(`link:${style.url}`)
      } else if (style.style === 'Size') {
        styledSegments[i].styles.push(`font-size:${+style.textSize + size}px;`)
      } else if (style.style === 'Strikethrough') {
        styledSegments[i].styles.push('line-through')
      }
    }
  })

  // 根据 styledSegments 来生成 HTML
  let currentStyles = []
  let insideStrikethrough = false
  let insideLink = false

  styledSegments.forEach((segment, index) => {
    const segmentStyles = segment.styles.filter((s) => s !== 'line-through' && !s.startsWith('link:')).join('')
    const hasStrikethrough = segment.styles.includes('line-through')
    const linkStyle = segment.styles.find((s) => s.startsWith('link:'))
    const linkUrl = linkStyle ? linkStyle.replace('link:', '') : null

    // 处理普通样式变化
    if (segmentStyles !== currentStyles.join('')) {
      if (currentStyles.length > 0) {
        result += '</span>'
      }
      result += `<span style="${segmentStyles}">`
      currentStyles = segment.styles.filter((s) => s !== 'line-through' && !s.startsWith('link:'))
    }

    // 处理 Strikethrough
    if (hasStrikethrough && !insideStrikethrough) {
      result += '<s>'
      insideStrikethrough = true
    } else if (!hasStrikethrough && insideStrikethrough) {
      result += '</s>'
      insideStrikethrough = false
      result += `<span style="${currentStyles.join('')}">`
    }

    // 处理 Link 开始
    if (linkUrl && !insideLink) {
      if (urlJump) {
        result += `<a class="link-text" onclick="linkClick('${linkUrl}')" style="${segmentStyles}">`
      } else {
        result += `<a class="color-link" style="${segmentStyles}">`
      }
      insideLink = true
    }

    result += segment.text

    // 处理 Link 结束
    if (!linkUrl && insideLink) {
      result += '</a>'
      insideLink = false
      result += `<span style="${currentStyles.join('')}">`
    }

    // 如果当前字符是最后一个 Link 的字符，关闭 <span> 标签
    if (insideLink && (index === styledSegments.length - 1 || !styledSegments[index + 1].styles.includes(linkStyle))) {
      result += '</a>'
      insideLink = false
      result += `<span style="${currentStyles.join('')}">`
    }
  })

  // 关闭所有未关闭的标签
  if (insideStrikethrough) {
    result += '</s>'
  }
  if (currentStyles.length > 0) {
    result += '</span>'
  }

  // 处理文字中的链接，并添加点击事件
  const tagRegex = /<[^>]+>/g
  // 存储标签
  const tags = []
  const noTagStr = result.replace(tagRegex, (match) => {
    tags.push(match)
    return `@@@${tags.length - 1}@@@` // 用特殊标记替换标签
  })
  result = noTagStr.replace(URL_REG, `<span class="${classNameList.join(' ')}" data-url="$&" ${urlJump ? 'onclick="linkClick(\'$&\')"' : ''}>$&</span>`)
  if (tags.length) {
    // 恢复标签
    result = result.replace(/@@@(\d+)@@@/g, (_, index) => {
      return tags[index]
    })
  }
  return result.replace(/\n/g, '<br/>')
}

/**
   * @description: 生成UUID
   * @return {string} 'b701d538-cba1-4538-982a-52b0a23ccb71'
   */
export function getUuid() {
  if (typeof crypto === 'object') {
    if (typeof crypto.randomUUID === 'function') {
      return crypto.randomUUID()
    }
    if (
      typeof crypto.getRandomValues === 'function' &&
        typeof Uint8Array === 'function'
    ) {
      const callback = (c) => {
        const num = Number(c)
        return (
          num ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))
        ).toString(16)
      }
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, callback)
    }
  }
  let timestamp = new Date().getTime()
  let perforNow =
      (typeof performance !== 'undefined' &&
        performance.now &&
        performance.now() * 1000) ||
      0
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let random = Math.random() * 16
    if (timestamp > 0) {
      random = (timestamp + random) % 16 | 0
      timestamp = Math.floor(timestamp / 16)
    } else {
      random = (perforNow + random) % 16 | 0
      perforNow = Math.floor(perforNow / 16)
    }
    return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16)
  })
}

/**
* 获取浏览器滚动条宽度
*/
export function getScrollbarWidth() {
  // 创建一个临时的 div 元素
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.width = '100px'
  outer.style.msOverflowStyle = 'scrollbar' // needed for WinJS apps

  // 将 div 添加到 body 中
  document.body.appendChild(outer)

  // 设置 div 的样式使其产生滚动条
  outer.style.overflow = 'scroll'

  // Firefox 滚动条宽度为 17px，其他浏览器为 0，所以需要一个额外的 div
  // 以确保在所有浏览器中都能正确检测滚动条宽度
  const inner = document.createElement('div')
  inner.style.width = '100%'
  outer.appendChild(inner)
  // 计算滚动条宽度
  const scrollbarWidth = outer.offsetWidth - outer.clientWidth
  // 清理
  outer.parentNode.removeChild(outer)
  return scrollbarWidth
}

export function objectToString(item) {
  if (item && typeof item === 'object') {
    return JSON.stringify(item)
  }
  return item
}

export function stringToObject(item) {
  if (item && typeof item === 'string') {
    try {
      return JSON.parse(item)
    } catch (error) {
      return item
    }
  }
  return item
}

export async function copyText(text) {
  try {
    const status = await navigator.permissions.query({
      name: 'clipboard-write'
    })
    if (status.state === 'granted' || status.state === 'prompt') {
      // 尝试写入剪贴板
      await navigator.clipboard.writeText(text)
    } else {
      throw new Error('No permission to write clipboard')
    }
  } catch (error) {
    const input = document.createElement('input')
    input.value = text
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
  }
  return true
}
