<template>
  <div class="aboutupay">
    <Header />
    <ReliableMessageFlatform />
    <DownloadMosapp />
    <PostList />
  </div>
</template>

<script>
import Header from '../components/home/Header.vue'
import ReliableMessageFlatform from '../components/home/ReliableMessageFlatform.vue'
import DownloadMosapp from '../components/home/DownloadMosapp.vue'
import PostList from '../components/home/PostList.vue'
export default {
  name: 'Home',
  components: {
    Header,
    ReliableMessageFlatform,
    DownloadMosapp,
    PostList
  },
  data() {
    return {
      isPauseVideo: false
    }
  },
  computed: {
  }
}
</script>

<style lang="less" scoped>
@baseColor: #e60013;
.aboutupay {
  .about {
    padding-bottom: 60px;
    /deep/ .content {
      min-height: 336px;
      // background: url("../assets/image/home/√-icon 背景@2x.png") no-repeat center;
      p {
        max-width: 896px;
        color: #333333;
        line-height: 31px;
        margin: 0 auto 31px;
      }
      @media screen and (max-width: 768px) {
        p {
          text-align: unset;
        }
      }
    }
  }
  .use {
    background: #f7f7f7;
    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      font-size: 14px;
      li {
        display: flex;
        margin: 0 20px 25px;
        flex-direction: column;
        align-items: center;
        width: 185px;
        text-align: center;
        color: #333333;
        img {
          height: 130px;
          width: 130px;
        }
        h3 {
          font-weight: bold;
          line-height: 1.8;
          color: @baseColor;
        }
        strong {
          font-weight: bold;
        }
        p {
          margin-top: 10px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      ul {
        margin: 0 -18px;
        li {
          width: 160px;
          margin: 0 10px 20px;
        }
      }
    }
  }
  .authentication {
    .top {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 400px;
      // background: url("../assets/image/home/背景图@2x.jpg") no-repeat center;
      background-size: cover;
      text-align: center;
      color: #ffffff;
      p {
        max-width: 1200px;
        margin: auto;
        font-size: 40px;
        color: #ffffff;
        strong {
          font-weight: 900;
        }
      }
      i {
        position: absolute;
        font-size: 25px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 43px;
      }
    }
    @media screen and (max-width: 992px) {
      .top {
        height: 280px;
        p {
          font-size: 24px;
        }
        i {
          bottom: 23px;
        }
      }
    }
    .bottom {
      display: flex;
      margin: 70px auto 80px;
      justify-content: center;
      flex-wrap: wrap;
      .item {
        display: flex;
        max-width: 320px;
        margin: 0 100px;
        flex-direction: column;
        align-items: center;
        color: #333333;
        img {
          height: 180px;
          width: 180px;
        }
        p {
          font-size: 14px;
          color: #333333;
          text-align: center;
        }
      }
      @media screen and (max-width: 992px) {
        margin: 0px auto 45px;
        .item {
          margin: 0 50px;
        }
      }
      @media screen and (max-width: 768px) {
        margin: 0px auto 45px;
        .item {
          margin: 0px;
        }
      }
    }
  }
  .partner {
    background-color: #f7f7f7;
    ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      li {
        margin: 0 30px 50px;
        img {
          width: 230px;
          height: 130px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      ul {
        margin: 0 -18px;
        li {
          margin: 0 10px 20px;
          img {
            width: 160px;
            height: 90.5px;
          }
        }
      }
    }
  }
}
</style>
