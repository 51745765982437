<template lang="">
  <div>
    <template v-if="$i18n.locale === 'en'">
      <div>
        <div class="banner">
          <div class="container">
            <el-row :gutter="20" class="content-head">
              <el-col :span="24">
                <router-link class="linkTo" to="/careers"><p style="margin-top: 100px; color: #3370FF">Career/</p></router-link>
                <h1>Android Engineer</h1>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="container" style="margin-top: 30px;">
          <ApplyModal />
          <el-row :gutter="24" style="margin-top: 40px">
            <el-col :span="18">
              <div class="career-detail">
                <h1>Job Description</h1>
                <p>MosApp is an instant messaging application that uses end-to-end encryption technology. It focuses on information security and protecting user privacy. The encrypted chat server does not save any information, and anyone outside the conversation cannot read or listen to it. You can safely chat and make audio and video calls with your friends and family. MosApp features include encrypted one-on-one and group chats, channel subscriptions, group chats with up to 10,000 people, burn after reading messages, two-way deletion, read receipts, and app lock screens.</p>
                <h1>Benefits</h1>
                <p>Good pay | Perfect attendance incentive | Employee referral incentive | NSSF | Opportunities for promotion | Large teamwork | Modern and comfortable office | Best office location | Public holidays follow labor law of the kingdom (100%) | Annual leave, maternity leave (3 months), paternity, special leave, sick leave, marriage leave, mourning leave...etc.</p>
                <h1>Qualification Requirement</h1>
                <ul class="list-style">
                  <li><p>More than 3 years of experience in Android system development.</p></li>
                  <li><p>Solid foundation in Java development and proficient in Kotlin for project development.</p></li>
                  <li><p>Experience in IM instant messaging development is preferred.</p></li>
                  <li><p>Experience in Flutter development is preferred.</p></li>
                </ul>
                <h1>Responsibility</h1>
                <ul class="list-style">
                  <li><p>Responsible for Android client requirement discussions and requirement design, and participate in product development.</p></li>
                  <li><p>Responsible for software architecture design and framework implementation.</p></li>
                  <li><p>Write design documents for corresponding modules, independently complete coding and unit testing.</p></li>
                  <li><p>Fully and effectively communicate and collaborate with team members, conduct technical risk assessment, and project time estimation.</p></li>
                </ul>
                <h1>Contact Info</h1>
                <p>CONTACT NAME : Ms.Camila</p>
                <!-- <p>ADDRESS : 111 SOMERSET ROAD #08-05, 111 SOMERSET SINGAPORE (238164)</p> -->
                <p>EMAIL : mosapp.app@gmail.com</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <h1 style="font-size:20px;">Job Details</h1>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">Employment Type</p>
                  <p>Full-time</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">Vacancy</p>
                  <p>15 Positions</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">Deadline</p>
                  <p>31 Nov 2023</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </template>
    <template v-if="$i18n.locale === 'zh'">
      <div>
        <div class="banner">
          <div class="container">
            <el-row :gutter="20" class="content-head">
              <el-col :span="24">
                <router-link class="linkTo" to="/careers"><p style="margin-top: 100px; color: #3370FF">职业/</p></router-link>
                <h1>Android工程师</h1>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="container" style="margin-top: 30px;">
          <ApplyModal />
          <el-row :gutter="24" style="margin-top: 40px">
            <el-col :span="18">
              <div class="career-detail">
                <h1>岗位描述</h1>
                <p>MosApp 是一款使用端到端加密技术的即时通讯应用。它专注于信息安全和保护用户隐私。加密聊天服务器不保存任何信息，对话外的任何人都无法阅读或收听。您可以安全地与朋友和家人进行聊天，并进行音频和视频通话。MosApp 的功能包括加密的一对一和群组聊天，频道订阅，最多可容纳 10,000 人的群组聊天，阅后即焚消息，双向删除，已读回执以及应用程序锁定屏幕。</p>
                <h1>福利待遇</h1>
                <p>Good pay | Perfect attendance incentive | Employee referral incentive | NSSF | Opportunities for promotion | Large teamwork | Modern and comfortable office | Best office location | Public holidays follow labor law of the kingdom (100%) | Annual leave, maternity leave (3 months), paternity, special leave, sick leave, marriage leave, mourning leave...etc.</p>
                <h1>资格要求</h1>
                <ul class="list-style">
                  <li><p>具有3年以上Android系统开发经验</p></li>
                  <li><p>有扎实的java开发基础，并熟练使用kotlin开发项目</p></li>
                  <li><p>有IM即时通讯开发经验优先</p></li>
                  <li><p>有Flutter开发经验优先</p></li>
                </ul>
                <h1>职责</h1>
                <ul class="list-style">
                  <li><p>负责Android客户端的需求讨论与需求设计并参与产品开发；</p></li>
                  <li><p>负责软件架构设计和框架搭建</p></li>
                  <li><p>编写相应模块的设计文档，独立完成编码及单元测试；</p></li>
                  <li><p>与团队成员充分.有效沟通协作，进行技术风险评估.项目时间评估。</p></li>
                </ul>
                <h1>联系信息</h1>
                <p>联系人姓名 : Ms.Camila</p>
                <!-- <p>地址 : 111 SOMERSET ROAD #08-05, 111 SOMERSET SINGAPORE (238164)</p> -->
                <p>电子邮件 : mosapp.app@gmail.com</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <h1 style="font-size:20px;">职位详情</h1>
                <div style="font-size: 20px; margin-top: 15px;">
                  <p style="color:#8C8C8C">雇佣类型</p>
                  <p>Full-time</p>
                </div>
                <div style="font-size: 20px; margin-top: 15px;">
                  <p style="color:#8C8C8C">空缺职位</p>
                  <p>15 Positions</p>
                </div>
                <div style="font-size: 20px; margin-top: 15px;">
                  <p style="color:#8C8C8C">截止日期</p>
                  <p>31 Nov 2023</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import ApplyModal from './ApplyModal.vue'

export default {
  components: {
    ApplyModal
  },
  data() {
    return { }
  }
}
</script>
<style lang="less" scoped>
.banner {
  position: relative;
  background-color: #E7EEFF99;
  background-position: center;
  height: auto;
  .container {
    color: #ffffff;
    position: relative;
    .content-head {
      padding-bottom: 40px;
      h1 {
        color: #1F1F1F;
        font-size: 50px;
        font-weight: 700;
      }
      p {
        font-size: 20px;
        font-weight: 400px;
      }
    }
  }
}
.career-detail {
  h1 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  p {
    font-size: 17px;
    margin-bottom: 30px;
  }
}
ul.list-style {
  p {
    margin: 0;
  }
  margin-left: 35px;
  margin-bottom: 30px;
  li {
    list-style-type: inherit;
  }
}
</style>
