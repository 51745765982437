<!--
 * @Author: Sky
 * @Date: 2024-09-09 11:52:11
 * @LastEditors: Sky
 * @LastEditTime: 2024-11-15 15:44:04
 * @Description: post list
-->
<template lang="">
  <div class="container">
    <h1 class="container-title">{{ $t("Home.postList") }}</h1>
    <div
      ref="listContainer"
      class="list-container"
      :class="{ 'big-screen': bigScreen }"
    >
      <template>
        <div
          ref="listItem"
          v-for="i in list"
          :key="i.id"
          class="main-item"
          :data-id="i.id"
          :data-cid="i.conversationId"
          :style="{
            width: itemWidth,
            marginBottom: rowGap
          }"
        >
          <!-- 图片 -->
          <div
            v-if="i.type === -2"
            class="image-container"
            :class="`image-type-${i.content.files.length}`"
          >
            <div
              v-for="(item, index) in i.content.files"
              :key="item.url"
              class="item"
              @click="showImgPreview(i.content.files, index)"
            >
              <el-image
                :src="item.url"
                fit="cover"
                lazy
                :preview-src-list="bigScreen ? previewList(i) : []"
              >
                <div slot="error" class="image-error">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
          </div>
          <!-- 音频 -->
          <div v-else-if="i.type === -3" class="audio-container">
            <div
              v-for="item in i.content.files"
              :key="item.url"
              class="audio-item"
            >
              <div
                class="audio-bg"
                :style="{
                  'background-image': `url(${i.senderHeadPortrait})`,
                }"
              ></div>
              <el-avatar
                :src="i.senderHeadPortrait"
                :size="102"
                class="audio-avatar"
              ></el-avatar>
              <div class="duration">
                <span class="duration-text">{{
                  (item.fileInfo.duration - item.currentTime) | secondFormat
                }}</span>
              </div>
              <div class="video-play" @click="togglePlayAudio(item)">
                <img
                  v-show="!item.isPlaying"
                  src="@/assets/svg/play.svg"
                  alt=""
                  class="audio-play-icon"
                />
                <img
                  v-show="item.isPlaying"
                  src="@/assets/svg/stop.svg"
                  alt=""
                  class="audio-pause-icon"
                />
              </div>
              <div class="progress-bar">
                <el-slider
                  v-model="item.currentTime"
                  :max="item.fileInfo.duration"
                  :show-tooltip="false"
                  @change="currentTimeChange(item)"
                ></el-slider>
              </div>
            </div>
          </div>
          <!-- 视频 -->
          <div v-else-if="i.type === -4" class="video-container">
            <div
              v-for="item in i.content.files"
              :key="item.url"
              class="video-img"
              @click="showVideo(item)"
            >
              <el-image :src="item.fileInfo?.thumbInfo?.url" fit="cover" lazy>
                <div slot="error" class="image-error">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <div class="duration">
                <span class="duration-text">{{
                  item.fileInfo.duration | secondFormat
                }}</span>
              </div>
              <div class="video-play">
                <img src="@/assets/svg/play-big.svg" alt="" />
              </div>
            </div>
          </div>
          <!-- 文件 -->
          <div v-else-if="i.type === -5" class="file-container">
            <div
              v-for="item in showFileList(i)"
              :key="item.url"
              class="file-item flex"
              @click="downFile(item)"
            >
              <img
                :src="getAssetsFile(`fileTypeIcons/${item.icon}`)"
                alt=""
                class="file-icon"
              />
              <div class="file-info">
                <div class="file-name overflow-ellipsis-2">
                  {{ item.name }}
                </div>
                <div class="file-size">{{ item.size | fileSizeFormat }}</div>
              </div>
            </div>
          </div>
          <!-- 红包 -->
          <div
            v-else-if="i.type === 6010"
            class="red-packet-container"
            @click="toApp(i)"
          >
            <div class="red-packet-box">
              <img
                src="@/assets/image/home/red-packet-icon-bg.svg"
                alt=""
                class="red-packet-icon-bg user-no-drag"
              />
              <img
                src="@/assets/image/home/red-packet-icon.svg"
                alt=""
                class="red-packet-icon user-no-drag"
              />
              <div class="red-packet-text none-select overflow-ellipsis-1">
                {{
                  i.content?.attrs?.blessingWords ||
                  $t("PostList.redPacketBlessingWords")
                }}
              </div>
            </div>
          </div>
          <!-- 文字 -->
          <div v-if="i.content?.text" class="text-container">
            <div
              :ref="`text-${i.id}`"
              class="text"
              :style="{
                height: i.maxHeight,
              }"
              v-html="i.content.text"
            ></div>
            <div
              v-if="i.showMore"
              class="show-more"
              :class="{ 'is-expend': i.isExpend }"
            >
              <span v-show="!i.isExpend" class="more-dot">...</span>
              <div
                class="show-more-btn pointer"
                @click="toggleExpend(i, 'list')"
              >
                {{ i.isExpend ? $t("PostList.fold") : $t("PostList.unfold") }}
              </div>
            </div>
          </div>
          <div>
            <!-- 标签 -->
            <div v-if="i.content?.categories" class="tag-list">
              <span
                v-for="tag in i.content?.categories"
                :key="tag.id"
                class="tag-item"
                >{{ tag.name }}</span
              >
            </div>
            <!-- 地理信息 -->
            <div
              v-if="i.content?.attrs?.locationInfo?.addressDetail"
              class="address-content flex-y-center"
            >
              <img src="@/assets/svg/address.svg" alt="" />
              <span class="address-text overflow-ellipsis-1">{{
                i.content.attrs.locationInfo.addressDetail
              }}</span>
            </div>
            <!-- 表态、浏览数量区域 -->
            <div class="action-content">
              <div class="action-content-left" @click="toApp(i)">
                <template v-if="interactionList(i).length">
                  <img
                    v-for="item in interactionList(i)"
                    :key="item.key"
                    :src="getAssetsFile(`interaction/${item.icon}`)"
                    alt=""
                    class="icon"
                  />
                </template>
                <template v-else>
                  <img
                    src="@/assets/image/home/interaction/LIKE.png"
                    alt=""
                    class="icon"
                  />
                </template>
                <span class="interaction-count">{{
                  i.interactionCount > 999 ? "999+" : i.interactionCount
                }}</span>
              </div>
              <div class="action-content-right">
                <div class="view-count">
                  <img src="@/assets/svg/viewCount.svg" alt="" />
                  <span class="count">{{ viewCount(i) }}</span>
                </div>
                <span class="create-time">{{ dateFormat(i.createTime) }}</span>
              </div>
            </div>
            <!-- 评论区 -->
            <div class="comment flex-y-center">
              <div class="flex-y-center">
                <div class="avatar-content flex-y-center">
                  <template v-if="commentAvatarList(i).length">
                    <el-avatar
                      v-for="(item, index) in commentAvatarList(i)"
                      :key="item"
                      :size="32"
                      :src="item"
                      :style="{
                        zIndex: commentAvatarList(i).length - index,
                      }"
                    ></el-avatar>
                  </template>
                  <template v-else>
                    <el-avatar
                      :size="32"
                      :src="i.senderHeadPortrait"
                      :style="{
                        zIndex: 0,
                      }"
                    ></el-avatar>
                  </template>
                </div>
                <span class="comment-text" @click="showComment(i)">{{
                  i.commentCount
                    ? $t("PostList.comment_count", { value: i.commentCount })
                    : $t("PostList.noCommentTips")
                }}</span>
              </div>
              <div class="forward-icon pointer" @click="forward(i)">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="18" cy="18" r="18" fill="currentColor"/>
                  <g clip-path="url(#clip0_20964_241395)">
                  <path d="M25.15 16.0599L20.78 11.4399C20.587 11.2309 20.3345 11.0861 20.0566 11.0252C19.7787 10.9642 19.4888 10.99 19.2261 11.0991C18.9633 11.2081 18.7403 11.3951 18.5872 11.6349C18.4341 11.8747 18.3583 12.1556 18.37 12.4399V14.3299C13.62 14.4999 11 16.5599 11 20.1399C10.9906 21.3542 11.2973 22.55 11.89 23.6099C11.9763 23.7677 12.1036 23.8993 12.2585 23.9908C12.4134 24.0823 12.5901 24.1304 12.77 24.1299C12.8597 24.1403 12.9503 24.1403 13.04 24.1299C13.26 24.0732 13.4541 23.9433 13.5905 23.7615C13.7268 23.5797 13.7971 23.357 13.79 23.1299C13.7331 22.7773 13.7518 22.4166 13.8449 22.0718C13.938 21.727 14.1034 21.4059 14.33 21.1299C14.97 20.4699 16.33 20.1299 18.33 20.0699V21.9999C18.3183 22.2841 18.3941 22.5651 18.5472 22.8049C18.7003 23.0447 18.9233 23.2317 19.1861 23.3407C19.4488 23.4497 19.7387 23.4755 20.0166 23.4146C20.2945 23.3536 20.547 23.2089 20.74 22.9999L25.11 18.3799C25.265 18.2302 25.389 18.0515 25.475 17.8539C25.5609 17.6562 25.607 17.4436 25.6108 17.2282C25.6145 17.0127 25.5757 16.7986 25.4966 16.5982C25.4176 16.3977 25.2998 16.2148 25.15 16.0599ZM19 18.7299C16.12 18.7299 14.32 19.1999 13.34 20.1999C12.9148 20.6288 12.6291 21.176 12.52 21.7699C12.3733 21.239 12.2992 20.6907 12.3 20.1399C12.34 16.4299 16 15.6499 19 15.6499C19.0896 15.6553 19.1794 15.6426 19.264 15.6126C19.3487 15.5826 19.4264 15.5359 19.4926 15.4753C19.5589 15.4146 19.6122 15.3413 19.6496 15.2596C19.6869 15.178 19.7074 15.0896 19.71 14.9999V12.3999C19.7055 12.3905 19.7032 12.3803 19.7032 12.3699C19.7032 12.3595 19.7055 12.3493 19.71 12.3399L24.18 16.9999C24.2327 17.0571 24.2619 17.1321 24.2619 17.2099C24.2619 17.2877 24.2327 17.3627 24.18 17.4199L19.81 21.9999C19.81 21.9999 19.81 21.9999 19.74 21.9999C19.67 21.9999 19.74 21.9999 19.74 21.9399V19.3999C19.7405 19.3057 19.7211 19.2124 19.6832 19.1262C19.6452 19.04 19.5895 18.9628 19.5197 18.8995C19.4498 18.8363 19.3675 18.7885 19.2779 18.7593C19.1884 18.7301 19.0936 18.72 19 18.7299Z" fill="#040000" fill-opacity="0.4"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_20964_241395">
                  <rect width="14.6" height="13.13" fill="white" transform="translate(11 11)"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-if="isError" class="loading-tip pointer" @click="reGet">{{ $t('PostList.load_failed')}}</div>
    <div
      v-else
      ref="loadingTip"
      id="loadingTip"
      class="loading-tip"
    >
      {{ finished ? $t("PostList.no_more_data") : $t("PostList.is_loading") }}
    </div>
    <!-- 音频播放组件 -->
    <audio v-if="currentUrl" ref="audioEl" :src="currentUrl"></audio>
    <!-- 图片预览弹窗 -->
    <van-image-preview
      v-if="!bigScreen && imgPreview"
      ref="imagePreview"
      v-model="imgPreview"
      :images="currentPreviewList"
      :start-position="imgPreviewIndex"
      :show-index="false"
      async-close
      :style="transformStyle"
      @change="onChange"
      @touchstart.native="getTouchstartY"
      @touchmove.native="getTouchmove"
      @touchend.native="touchEnd"
    >
      <template v-slot:cover>
        <div v-show="!moveY" class="top-content">
          <div class="close-icon" @click="handleClosePreview">
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.7217 25.7216C26.0929 25.3503 26.0929 24.7485 25.7217 24.3772L20.3444 19L25.7216 13.6227C26.0928 13.2515 26.0928 12.6497 25.7216 12.2784C25.3503 11.9072 24.7485 11.9072 24.3772 12.2784L19 17.6556L13.6228 12.2784C13.2516 11.9072 12.6497 11.9072 12.2785 12.2784C11.9073 12.6496 11.9073 13.2515 12.2785 13.6227L17.6557 19L12.2784 24.3773C11.9072 24.7485 11.9072 25.3504 12.2784 25.7216C12.6496 26.0928 13.2515 26.0928 13.6227 25.7216L19 20.3443L24.3773 25.7216C24.7486 26.0928 25.3504 26.0928 25.7217 25.7216Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="preview-index">{{ imgPreviewIndex + 1 }}/{{ currentPreviewList.length }}</div>
          <div class="down-icon" @click="downFile(currentFileList[imgPreviewIndex])">
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
              <path
                d="M19 22.7232C19.2318 22.7232 19.453 22.6257 19.6094 22.4546L23.5069 18.1918C23.8146 17.8552 23.7912 17.3329 23.4546 17.0252C23.1181 16.7174 22.5957 16.7408 22.288 17.0774L19.8258 19.7705V9.23077C19.8258 8.77471 19.4561 8.405 19 8.405C18.5439 8.405 18.1742 8.77471 18.1742 9.23077V19.7705L15.712 17.0774C15.4043 16.7408 14.8819 16.7174 14.5454 17.0252C14.2088 17.3329 14.1854 17.8552 14.4931 18.1918L18.3906 22.4546C18.547 22.6257 18.7682 22.7232 19 22.7232ZM11.0565 20.9231C11.0565 20.467 10.6868 20.0973 10.2308 20.0973C9.77471 20.0973 9.405 20.467 9.405 20.9231V20.9765L9.405 20.9833C9.40498 22.3103 9.40496 23.3895 9.51938 24.2405C9.63871 25.128 9.89592 25.8899 10.503 26.497C11.1101 27.1041 11.872 27.3613 12.7595 27.4806C13.6105 27.595 14.6897 27.595 16.0166 27.595H16.0235H21.9765H21.9834C23.3103 27.595 24.3895 27.595 25.2405 27.4806C26.128 27.3613 26.8899 27.1041 27.497 26.497C28.1041 25.8899 28.3613 25.128 28.4806 24.2405C28.595 23.3895 28.595 22.3102 28.595 20.9833V20.9765V20.9231C28.595 20.467 28.2253 20.0973 27.7692 20.0973C27.3132 20.0973 26.9435 20.467 26.9435 20.9231C26.9435 22.3244 26.9417 23.2923 26.8438 24.0205C26.7491 24.7248 26.5783 25.0801 26.3292 25.3292C26.0801 25.5783 25.7248 25.7491 25.0205 25.8438C24.2923 25.9417 23.3244 25.9435 21.9231 25.9435H16.0769C14.6756 25.9435 13.7077 25.9417 12.9795 25.8438C12.2752 25.7491 11.9199 25.5783 11.6708 25.3292C11.4217 25.0801 11.2509 24.7248 11.1562 24.0205C11.0583 23.2923 11.0565 22.3244 11.0565 20.9231Z"
                fill="white"
                stroke="white"
                stroke-width="0.19"
              />
            </svg>
          </div>
        </div>
      </template>
    </van-image-preview>
    <!-- 查看评论弹窗 -->
    <van-popup
      v-model="commentShow"
      :position="bigScreen ? 'center' : 'bottom'"
      class="comment-popup-wrapper"
      :class="{ big: bigScreen }"
    >
      <div class="comment-container">
        <div class="top-header">
          <div>
            {{ $t("PostList.comment") }} {{ currentDetail.commentCount }}
          </div>
          <van-icon name="cross" class="pointer" @click="commentShow = false" />
        </div>
        <van-list
          v-model="commentLoading"
          :finished="commentFinished"
          :loading-text="$t('PostList.is_loading')"
          :finished-text="$t('PostList.no_more_data')"
          :error-text="$t('PostList.load_failed')"
          :error.sync="commentError"
          @load="getCommentList"
        >
          <div class="comment-list">
            <div
              v-for="item in commentList"
              :key="item.id"
              class="comment-item flex"
            >
              <el-avatar
                :size="32"
                :src="item.userHeadPortrait"
                :style="{ marginRight: '8px' }"
              ></el-avatar>
              <div class="flex-1">
                <div class="flex flex-y-center flex-x-sb">
                  <div>
                    <div class="flex flex-y-center">
                      <div class="comment-name">{{ item.nickName }}</div>
                      <span
                        v-if="[2, 3].includes(item.role)"
                        class="user-tag"
                        :class="item.role === 3 ? 'creator-tag' : 'admin-tag'"
                        >{{
                          item.role === 3
                            ? $t("PostList.creator")
                            : $t("PostList.admin")
                        }}</span
                      >
                    </div>
                    <div class="comment-time">
                      {{ dateFormat(item.createTime) }}
                    </div>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="pointer" @click="copyHandle(item.content)">
                    <path d="M17.752 7.03749H9.85728C8.03833 7.03749 7.03773 8.03899 7.03773 9.85704V17.7518C7.03773 19.5698 8.03833 20.5713 9.85728 20.5713H17.752C19.571 20.5713 20.5716 19.5698 20.5716 17.7518V9.85704C20.5716 8.03899 19.571 7.03749 17.752 7.03749ZM19.6693 17.7518C19.6693 19.0781 19.0783 19.6691 17.752 19.6691H9.85728C8.53097 19.6691 7.93999 19.0781 7.93999 17.7518V9.85704C7.93999 8.53072 8.53097 7.93974 9.85728 7.93974H17.752C19.0783 7.93974 19.6693 8.53072 19.6693 9.85704V17.7518ZM14.7069 4.33072H6.24826C4.92194 4.33072 4.33097 4.9217 4.33097 6.24802V14.7067C4.33097 14.9557 4.12886 15.1578 3.87984 15.1578C3.63082 15.1578 3.42871 14.9557 3.42871 14.7067V6.24802C3.42871 4.42997 4.42931 3.42847 6.24826 3.42847H14.7069C14.9559 3.42847 15.158 3.63057 15.158 3.87959C15.158 4.12862 14.9559 4.33072 14.7069 4.33072Z" fill="black" stroke="black" stroke-width="0.6"/>
                  </svg>
                </div>
                <div class="comment-content">
                  <div
                    :ref="`commentText-${item.id}`"
                    class="text"
                    :style="{
                      height: item.maxHeight,
                    }"
                  >{{ item.content }}</div>
                  <div
                    v-if="item.showMore"
                    class="show-more comment-more"
                    :class="{ 'is-expend': item.isExpend }"
                  >
                    <span v-show="!item.isExpend" class="more-dot">...</span>
                    <div
                      class="show-more-btn pointer"
                      @click="toggleExpend(item, 'comment')"
                    >
                      {{
                        item.isExpend
                          ? $t("PostList.fold")
                          : $t("PostList.unfold")
                      }}
                    </div>
                  </div>
                </div>
                <div class="comment-bottom">
                  <div
                    v-if="item.replyCount && !item.isExpend"
                    class="comment-reply pointer"
                    @click="showReply(item)"
                  >
                    {{ $t("PostList.more_replies", [item.replyCount]) }}
                  </div>
                  <div class="comment-like pointer" @click="toApp(currentDetail)">
                    <img src="@/assets/svg/heart.svg" alt="" />
                    <span class="like-count">{{ item.likeCount }}</span>
                  </div>
                </div>
                <div v-if="item.replyLoading" class="comment-reply-loading">
                  <i class="el-icon-loading"></i>{{ $t("PostList.is_loading") }}
                </div>
                <div
                  v-if="item.replyList?.length"
                  class="comment-reply-container"
                >
                  <div
                    v-for="reply in item.replyList"
                    :key="reply.id"
                    class="comment-reply-item flex"
                  >
                    <el-avatar
                      :size="32"
                      :src="reply.userHeadPortrait"
                      :style="{ marginRight: '8px' }"
                    ></el-avatar>
                    <div class="flex-1">
                      <div class="flex flex-y-center flex-x-sb">
                        <div>
                          <div class="flex flex-y-center">
                            <div class="comment-name">{{ reply.nickName }}</div>
                            <span
                              v-if="[2, 3].includes(reply.role)"
                              class="user-tag"
                              :class="
                                reply.role === 3 ? 'creator-tag' : 'admin-tag'
                              "
                              >{{
                                reply.role === 3
                                  ? $t("PostList.creator")
                                  : $t("PostList.admin")
                              }}</span
                            >
                          </div>
                          <div class="comment-time">
                            {{ dateFormat(reply.createTime) }}
                          </div>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="pointer" @click="copyHandle(item.content)">
                          <path d="M17.752 7.03749H9.85728C8.03833 7.03749 7.03773 8.03899 7.03773 9.85704V17.7518C7.03773 19.5698 8.03833 20.5713 9.85728 20.5713H17.752C19.571 20.5713 20.5716 19.5698 20.5716 17.7518V9.85704C20.5716 8.03899 19.571 7.03749 17.752 7.03749ZM19.6693 17.7518C19.6693 19.0781 19.0783 19.6691 17.752 19.6691H9.85728C8.53097 19.6691 7.93999 19.0781 7.93999 17.7518V9.85704C7.93999 8.53072 8.53097 7.93974 9.85728 7.93974H17.752C19.0783 7.93974 19.6693 8.53072 19.6693 9.85704V17.7518ZM14.7069 4.33072H6.24826C4.92194 4.33072 4.33097 4.9217 4.33097 6.24802V14.7067C4.33097 14.9557 4.12886 15.1578 3.87984 15.1578C3.63082 15.1578 3.42871 14.9557 3.42871 14.7067V6.24802C3.42871 4.42997 4.42931 3.42847 6.24826 3.42847H14.7069C14.9559 3.42847 15.158 3.63057 15.158 3.87959C15.158 4.12862 14.9559 4.33072 14.7069 4.33072Z" fill="black" stroke="black" stroke-width="0.6"/>
                        </svg>
                      </div>
                      <div class="comment-content">
                        <span class="reply-name">@{{ reply.replyNickName }}</span>
                        <span class="rich-text">{{ reply.content }}</span>
                      </div>
                      <div class="comment-bottom">
                        <div class="comment-like pointer" @click="toApp(currentDetail)">
                          <img src="@/assets/svg/heart.svg" alt="" />
                          <span class="like-count">{{ reply.likeCount }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="comment-bottom">
                    <div
                      v-if="item.replyList.length < item.replyTotal"
                      class="comment-reply pointer"
                      @click="getReplyList(item)"
                    >
                      {{ $t("PostList.unfold_more_replies") }}
                    </div>
                    <div
                      class="comment-reply fold pointer"
                      @click="unfoldReply(item)"
                    >
                      {{ $t("PostList.fold") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </van-popup>
    <!-- 视频播放的弹窗 -->
    <van-popup
      v-if="videoShow"
      v-model="videoShow"
      :position="bigScreen ? 'center' : 'bottom'"
      class="video-popup-wrapper"
      :class="{ big: bigScreen }"
    >
      <div class="video-popup">
        <div class="flex flex-y-center flex-x-sb video-header">
          <van-icon
            name="cross"
            color="#fff"
            class="pointer"
            @click="videoShow = false"
          ></van-icon>
          <span v-if="bigScreen"></span>
          <van-popover
            v-else
            v-model="videoDownloadShow"
            trigger="click"
            placement="bottom-end"
            class="download-popover-wrapper"
          >
            <div
              class="download-popover flex flex-y-center flex-x-sb pointer"
              @click.stop="downFile(currentVideo)"
            >
              <span>{{ $t("PostList.download") }}</span>
              <img src="@/assets/svg/download.svg" alt="" />
            </div>
            <template #reference>
              <img src="@/assets/svg/more-white.svg" alt="" class="pointer" />
            </template>
          </van-popover>
        </div>
        <div id="videoPlayer"></div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import moment from 'moment'
import Player from 'xgplayer'
import 'xgplayer/dist/index.min.css'
import Masonry from 'masonry-layout'
import { Message } from 'element-ui'
import { Icon, Popup, List, ImagePreview, Popover } from 'vant'
import 'vant/lib/index.css'
import {
  getOfficalTopicPage,
  webIncrViewCount,
  getTopicComment,
  getTopicReply
} from '@/api/topic'
import {
  copyText,
  getUuid,
  stringToObject,
  getAssetsFile,
  generateHtmlFromJson,
  getScrollbarWidth
} from '@/utils'

const DEVICE_UUID = localStorage.getItem('deviceUuid') || getUuid()
if (!localStorage.getItem('deviceUuid')) {
  localStorage.setItem('deviceUuid', DEVICE_UUID)
}
const SHARE_HOST_NAME = location.host.includes('test')
  ? 'https://test.mos.me'
  : 'https://mos.me'
export default {
  name: 'PostList',
  components: {
    VanIcon: Icon,
    VanPopup: Popup,
    VanList: List,
    [ImagePreview.Component.name]: ImagePreview.Component,
    VanPopover: Popover
  },
  data() {
    return {
      getAssetsFile,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageNum: 1,
      pageSize: 30,
      total: 0,
      textStyles: {},
      pageType: 1, // 1: 个人 2: 加密群组 3: 公开频道 4: 帖子 5: 普通群组 6: 客服 7: 私密频道
      imgPreview: false,
      imgPreviewIndex: 1,
      startY: 0,
      startX: 0,
      imgScale: 1,
      moveY: 0,
      moveX: 0,
      transformStyle: {},
      screenWidth: null,
      colGap: '30px', // 列间距
      rowGap: '20px', // 行间距
      itemWidth: '280px', // 每一项的宽度
      initialHeight: '',
      showMore: false,
      isExpend: false,
      serverData: {},
      fileList: [],
      currentUrl: '',
      isPlaying: false,
      currentTime: 0,
      downList: [
        {
          type: 'IOS',
          value: 'appstore',
          title: 'App Store',
          icon: 'Apple.png',
          url: 'https://apps.apple.com/cn/app/mosapp/id6446265600'
        },
        {
          type: 'Android',
          value: 'playstore',
          title: 'Android',
          icon: 'Android.png',
          url: 'https://cdn-oss.metathought.cc/public/mosapp/app/MosApp-Android-Latest.apk'
        },
        {
          type: 'Windows',
          value: 'windows',
          title: 'Windows',
          icon: 'Windows.png',
          url: 'https://cdn-oss.metathought.cc/public/mosapp/pc/MosApp-Windows-Latest.exe'
        },
        {
          type: 'MacOS',
          value: 'mac',
          title: 'MacOS',
          icon: 'Apple.png',
          url: 'https://cdn-oss.metathought.cc/public/mosapp/pc/MosApp-MacOS-Latest.dmg'
        }
      ],
      playVideo: false,
      currentEl: null,
      currentFileList: [],
      commentShow: false,
      commentList: [],
      commentLoading: false,
      commentTotal: 0,
      commentPageNum: 1,
      commentPageSize: 20,
      commentFinished: false,
      commentError: false,
      currentDetail: {},
      listLineHeight: '24px', // 列表中文本的行高
      listLineClamp: 14, // 列表中文本显示的行数
      commentLineHeight: '20px', // 评论的行高
      commentLineClamp: 3, // 评论显示的行数
      observer: null,
      setViewCountTimer: null,
      readTopicIds: [],
      isError: false,
      currentAudio: null,
      currentVideo: null,
      videoShow: false,
      videoDownloadShow: false,
      msnry: null,
      scrollBarWidth: 0 // 滚动条宽度
    }
  },
  filters: {
    // 秒转为时分秒
    secondFormat(time, str = 'm:s') {
      const timeNum = Number(time)
      if (!timeNum) return '00:00'
      let h = parseInt(time / 60 / 60)
      if (h < 24) {
        h = (parseInt((time / 60 / 60) % 24) + '').padStart(2, 0)
      } else {
        h = parseInt(time / 60 / 60) + ''
      }
      const m = (parseInt((time / 60) % 60) + '').padStart(2, 0)
      const s = (parseInt(time % 60) + '').padStart(2, 0)
      if (str.includes('h')) {
        return `${h}:${m}:${s}`
      } else {
        if (h === '00') {
          if (!str.includes('m') && m === '00') {
            if (Number(s)) return `${Number(s)}s`
            return 0
          }
          return `${m}:${s}`
        } else {
          return `${h}:${m}:${s}`
        }
      }
    },
    fileSizeFormat(size, isInt) {
      if (!size) return '0KB'
      if (size < 1024) {
        return size + ' B'
      } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed(isInt ? 0 : 2) + ' KB'
      } else {
        return (size / 1024 / 1024).toFixed(isInt ? 0 : 2) + ' MB'
      }
    }
  },
  computed: {
    // 评论的最大高度
    listMaxHeight() {
      return parseInt(this.listLineHeight) * this.listLineClamp
    },
    // 评论的最大高度
    commentMaxHeight() {
      return parseInt(this.commentLineHeight) * this.commentLineClamp
    },
    // 是否是大屏
    bigScreen() {
      return this.screenWidth > 760
    },
    isMobile() {
      return !!navigator.userAgent.match(
        /(iPhone|iPod|Android|ios|iOS|WebOS|Windows Phone|Phone)/i
      )
    },
    isMacOs() {
      return /macintosh|mac os x/i.test(navigator.userAgent)
    },
    isWindows() {
      const agent = navigator.userAgent.toLowerCase()
      if (agent.indexOf('win32') >= 0 || agent.indexOf('wow32') >= 0) {
        return true
      }
      if (agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0) {
        return true
      }
      return false
    },
    isAndroid() {
      return /android/i.test(navigator.userAgent.toLowerCase())
    },
    isIOS() {
      const reg = /iPhone|iPad|iOS|Macintosh/i
      return reg.test(navigator.userAgent.toLowerCase())
    },
    previewList() {
      return function (item) {
        const list = []
        if (item.content.files && item.content.files.length) {
          item.content.files.forEach((i) => {
            i.url && list.push(i.url)
          })
        }
        return list
      }
    },
    // 表态的列表
    interactionList() {
      return function (item) {
        const list = []
        if (item.interactionSort) {
          item.interactionSort.split(',').forEach((i) => {
            list.push({
              key: i,
              icon: `${i}.png`
            })
          })
        }
        return list
      }
    },
    // 浏览数量
    viewCount() {
      return function (item) {
        let count = isNaN(item.readCount) ? 0 : item.readCount
        // 如果大于1亿，显示99+
        if (count >= 100000000) {
          count = '99+'
          return count
        }
        if (count >= 1000 && count < 1000000) {
          count = (count / 1000).toFixed(1) + 'K'
        } else if (count >= 1000000) {
          count = (count / 1000000).toFixed(1) + 'M'
        }
        count = count.toString()
        if (count.includes('.0K') || count.includes('.0M')) {
          count = count.replace('.0', '')
        }
        return count
      }
    },
    // 评论的头像列表，只取前5个
    commentAvatarList() {
      return function (item) {
        let list = item.userHeadPortrait
          ? item.userHeadPortrait.split(',')
          : []
        if (list.length > 5) {
          list = list.slice(0, 5)
        }
        return list
      }
    },
    // 文件消息的文件列表，只取前3个
    showFileList() {
      return function (item) {
        if (item.type !== -5) return []
        let list = item.content.files
        if (list.length > 3) {
          list = list.slice(0, 3)
        }
        list.map((i) => {
          const exeName = i.name.split('.').pop().toLowerCase()
          if (exeName) {
            if (
              exeName === 'pdf' ||
              exeName === 'rar' ||
              exeName === 'zip' ||
              exeName === 'txt'
            ) {
              i.icon = exeName + '.png'
            } else if (['doc', 'docx'].includes(exeName)) {
              i.icon = 'doc.png'
            } else if (['xls', 'xlsx'].includes(exeName)) {
              i.icon = 'xls.png'
            } else if (['ppt', 'pptx'].includes(exeName)) {
              i.icon = 'ppt.png'
            } else {
              i.icon = 'unknow.png'
            }
          } else {
            i.icon = 'unknow.png'
          }
        })
        return list
      }
    },
    currentPreviewList() {
      return this.currentFileList.map((i) => i.url)
    }
  },
  watch: {
    currentUrl: {
      handler(newVal) {
        this.$nextTick(() => {
          if (newVal && this.$refs.audioEl) {
            this.$refs.audioEl.addEventListener('play', this.handlePlay)
            this.$refs.audioEl.addEventListener('pause', this.handlePause)
            this.$refs.audioEl.addEventListener(
              'timeupdate',
              this.handleTimeupdate
            )
            this.$refs.audioEl.addEventListener('ended', this.handleEnded)
            this.$refs.audioEl.addEventListener('error', this.handleError)
          }
        })
      },
      immediate: true
    }
  },
  mounted() {
    this.scrollBarWidth = getScrollbarWidth()
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
    document.addEventListener('click', this.handleClickOutside)
    this.handleObserver()
    this.onRefresh()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    document.removeEventListener('click', this.handleClickOutside)
    if (this.$refs.audioEl) {
      this.$refs.audioEl.removeEventListener('play', this.handlePlay)
      this.$refs.audioEl.removeEventListener('pause', this.handlePause)
      this.$refs.audioEl.removeEventListener(
        'timeupdate',
        this.handleTimeupdate
      )
      this.$refs.audioEl.removeEventListener('ended', this.handleEnded)
      this.$refs.audioEl.removeEventListener('error', this.handleError)
    }
  },
  methods: {
    reGet() {
      this.isError = false
      this.getList()
    },
    dateFormat(date) {
      if (!date) return ''
      const currentDate = moment()
      const currentDateStart = moment().startOf('day')
      const targetDate = moment(date)
      const targetDateStart = moment(date).startOf('day')
      // 时间差异使用当天的0点比较，否则不满24小时会变成是同一天
      const diffDays = currentDateStart.diff(targetDateStart, 'day')
      if (diffDays === 0) {
        return targetDate.format('HH:mm')
      } else if (diffDays === 1) {
        return this.$t('PostList.yesterday')
      } else if (diffDays >= 2 && diffDays <= 7) {
        const num = targetDate.format('d')
        let str
        switch (num) {
          case '1':
            str = this.$t('PostList.monday')
            break
          case '2':
            str = this.$t('PostList.tuesday')
            break
          case '3':
            str = this.$t('PostList.wednesday')
            break
          case '4':
            str = this.$t('PostList.thursday')
            break
          case '5':
            str = this.$t('PostList.friday')
            break
          case '6':
            str = this.$t('PostList.saturday')
            break
          case '0':
            str = this.$t('PostList.sunday')
            break
          default:
            str = targetDate.format('dddd')
            break
        }
        return str
      } else if (diffDays > 7) {
        if (targetDate.year() < currentDate.year()) {
          return targetDate.format('YYYY/MM/DD')
        } else {
          return targetDate.format('MM/DD')
        }
      }
    },
    // 音频播放点击外部时暂停播放
    handleClickOutside(event) {
      if (
        this.currentAudio &&
        this.currentAudio.isPlaying &&
        this.$refs.audioEl &&
        !event.target.classList?.contains('audio-play-icon') &&
        !event.target.classList?.contains('audio-pause-icon') &&
        !event.target.classList?.contains('el-slider__bar') &&
        !event.target.classList?.contains('el-slider__runway')
      ) {
        this.$refs.audioEl.pause()
      }
    },
    async forward(item) {
      const url = `${SHARE_HOST_NAME}/${item.linkInfo}`
      if (navigator.share && !this.bigScreen) {
        const shareData = {
          url
        }
        if (item.content?.originalText) {
          shareData.title = item.content.originalText
        }
        navigator.share(shareData)
      } else {
        await copyText(url)
        this.$toast.success(this.$t('PostList.copySuccess'))
      }
    },
    setViewCount() {
      // 2秒内只执行一次
      this.setViewCountTimer = setTimeout(async () => {
        if (!this.readTopicIds.length) return
        await webIncrViewCount({
          topics: this.readTopicIds,
          uuid: DEVICE_UUID
        })
        this.readTopicIds = []
      }, 2000)
    },
    // 监听列表中的元素是否显示在视图上
    handleObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.setViewCountTimer && clearTimeout(this.setViewCountTimer)
            this.readTopicIds.push({
              conversationId: entry.target.getAttribute('data-cid'),
              topicId: entry.target.getAttribute('data-id')
            })
            this.observer.unobserve(entry.target)
          }
        })
        if (this.readTopicIds.length) {
          this.setViewCount()
        }
      }, options)
      // 加载下一页监听
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (this.finished) {
              observer.unobserve(entry.target)
              return
            }
            this.getList()
          }
        })
      }, {
        root: null,
        threshold: 0,
        rootMargin: '2000px'
      })
      observer.observe(this.$refs.loadingTip)
    },
    async copyHandle(text) {
      if (text) {
        await copyText(text)
        this.$toast.success(this.$t('PostList.copySuccess'))
      }
    },
    async getCommentList() {
      this.commentLoading = true
      if (this.commentPageNum === 1) {
        this.commentFinished = false
        this.commentTotal = 0
        this.commentList = []
      }
      const res = await getTopicComment({
        topicId: this.currentDetail.id,
        pageNum: this.commentPageNum,
        pageSize: this.commentPageSize
      })
      const { code, data } = res
      this.commentLoading = false
      if (code !== 200) {
        this.commentError = true
        return
      }
      this.commentList = this.commentList.concat(data.records)
      this.commentTotal = data.total
      this.commentPageNum++
      if (this.commentList.length >= this.commentTotal) {
        this.commentFinished = true
      }
      this.$nextTick(() => {
        this.commentList.forEach((item, index) => {
          // 没有设置过才需要处理
          if (!('showMore' in item)) {
            if (this.$refs[`commentText-${item.id}`]) {
              const scrollHeight =
                this.$refs[`commentText-${item.id}`][0].scrollHeight
              if (scrollHeight > this.commentMaxHeight) {
                this.$set(item, 'showMore', true)
                this.$set(item, 'maxHeight', this.commentMaxHeight + 'px')
              } else {
                this.$set(item, 'showMore', false)
              }
            } else {
              this.$set(item, 'showMore', false)
            }
          }
        })
      })
    },
    async showComment(item) {
      // 如果没有评论，移动端跳转到应用、pc端提示
      if (!item.commentCount) {
        this.toApp(item)
        return
      }
      this.commentPageNum = 1
      this.currentDetail = item
      // 清空列表数据
      this.commentFinished = true
      this.commentLoading = true
      this.getCommentList()
      this.commentShow = true
    },
    async getReplyList(item) {
      this.$set(item, 'replyLoading', true)
      if (item.replyPageNum === 1) {
        this.$set(item, 'replyTotal', 0)
        this.$set(item, 'replyList', [])
      }
      const res = await getTopicReply({
        commentId: item.id,
        pageNum: item.replyPageNum,
        pageSize: 10
      })
      const { data } = res
      this.$set(item, 'replyLoading', false)
      item.replyList = item.replyList.concat(data.records)
      item.replyTotal = data.total
      this.$set(item, 'replyPageNum', item.replyPageNum + 1)
    },
    async showReply(item) {
      this.$set(item, 'isExpend', true)
      this.$set(item, 'replyPageNum', 1)
      this.getReplyList(item)
    },
    unfoldReply(item) {
      this.$set(item, 'isExpend', false)
      this.$set(item, 'replyPageNum', 1)
      this.$set(item, 'replyList', [])
    },
    handleMasonry() {
      this.$nextTick(() => {
        this.msnry = new Masonry('.list-container', {
          itemSelector: '.main-item',
          columnWidth: '.main-item',
          gutter: 30
        })
      })
    },
    async getList() {
      this.loading = true
      if (this.refreshing) {
        this.pageNum = 1
        this.refreshing = false
      }
      if (this.pageNum === 1) {
        this.list = []
      }
      const res = await getOfficalTopicPage({
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).catch((error) => {
        console.log('error', error)
        this.isError = true
      })
      if (!res) return
      const { code, data, message } = res
      // 解散6016 非公开6017 冻结6018
      if ([5000, 6016, 6017, 6018].includes(code)) {
        if (message) {
          this.loading = false
          this.$toast.fail(message)
        }
        this.isError = true
        return
      }
      this.total = data.total
      const records = data.records.map((item) => {
        if (item.content) {
          item.content = JSON.parse(item.content)
          if (item.content?.attrs?.locationInfo) {
            item.content.attrs.locationInfo = JSON.parse(
              item.content.attrs.locationInfo
            )
          }
          if (item.content.text) {
            item.content.originalText = item.content.text
            item.content.text = generateHtmlFromJson(
              item.content.text,
              item.content?.textStyles
            )
          }
          if (item.content.files) {
            item.content.files = item.content.files.map((file) => {
              file.fileInfo = stringToObject(file.fileInfo)
              return file
            })
          }
        }
        return item
      })
      this.list = this.list.concat(records)
      this.pageNum++
      this.loading = false
      if (this.list.length >= this.total) {
        this.finished = true
      }
      // 处理是否显示展开
      this.$nextTick(() => {
        this.list.forEach((item, index) => {
          // 没有设置过才需要处理
          if (!('showMore' in item)) {
            this.observer.observe(this.$refs.listItem[index])
            if (this.$refs[`text-${item.id}`]) {
              const scrollHeight =
                this.$refs[`text-${item.id}`][0].scrollHeight
              if (scrollHeight > this.listMaxHeight) {
                this.$set(item, 'showMore', true)
                this.$set(item, 'maxHeight', this.listMaxHeight + 'px')
              } else {
                this.$set(item, 'showMore', false)
              }
            } else {
              this.$set(item, 'showMore', false)
            }
          }
        })
        if (this.bigScreen) {
          this.$nextTick(() => {
            this.handleMasonry()
          })
        }
      })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      this.pageNum = 1
      // 重新加载数据
      this.getList()
    },
    linkClick(url) {
      if (!/^https?:\/\//i.test(url)) {
        url = 'https://' + url
      }
      window.location.href = url
    },
    getTouchstartY(e) {
      if (e.targetTouches.length === 1) {
        // 初始style
        this.transformStyle = {
          transition: ''
        }
        // 获取触摸目标在视口中的位置
        this.startY = e.targetTouches[0].clientY
        this.startX = e.targetTouches[0].clientX
      }
    },
    getTouchmove(e) {
      e.preventDefault()
      if (e.targetTouches.length === 1) {
        // 获取滑动的距离
        this.moveY = e.targetTouches[0].clientY - this.startY
        this.moveX = e.targetTouches[0].clientX - this.startX
        // 设置图片缩放的大小
        this.imgScale = 1 - Math.abs(this.moveY) / 500
        if (this.moveY) {
          this.transformStyle = {
            transform: `translate(${this.moveX}px,${this.moveY}px) scale(${this.imgScale})`
          }
          document.querySelector('.van-image-preview__overlay').style.opacity =
            this.imgScale
        }
      }
    },
    touchEnd() {
      // 如果触摸滑动的距离大于130px，就关闭图片，关闭弹窗
      if (Math.abs(this.moveY) > 130) {
        this.transformStyle = {
          transform: 'scale(0)'
        }
        this.handleClosePreview()
      } else {
        // 如果触摸滑动的距离未超过130px，实现图片还原
        this.handleResetMove()
      }
    },
    // 重置滑动的信息
    handleResetMove() {
      this.startY = 0
      this.startX = 0
      this.imgScale = 1
      this.moveY = 0
      this.moveX = 0
      this.transformStyle = {
        transform: 'translateY(0) scale(1)'
      }
      document.querySelector('.van-image-preview__overlay').style.opacity = 1
    },
    handleClosePreview() {
      this.imgPreview = false
      this.handleResetMove()
    },
    showImgPreview(fileList, index) {
      if (this.bigScreen) return
      this.currentFileList = fileList
      this.imgPreviewIndex = index
      this.imgPreview = true
    },
    onChange(index) {
      this.imgPreviewIndex = index
    },
    toggleExpend(item, type) {
      this.$set(item, 'isExpend', !item.isExpend)
      if (item.showMore && !item.isExpend) {
        if (type === 'comment') {
          this.$set(item, 'maxHeight', this.commentMaxHeight + 'px')
        } else {
          this.$set(item, 'maxHeight', this.listMaxHeight + 'px')
        }
      } else {
        this.$set(item, 'maxHeight', 'auto')
      }
      // 展开后瀑布流列表需重新布局
      if (type === 'list') {
        this.handleMasonry()
      }
    },
    handleResize() {
      this.screenWidth = window.innerWidth
      this.setVh()
      if (this.bigScreen) {
        const containerWidth = this.$refs.listContainer.clientWidth
        // 定义列数量，每一列最小宽度为280px，每一列之间的间距为30px，280 * colNum + (colNum - 1) * 30 = containerWidth
        let colNum
        if (containerWidth >= 1830) {
          colNum = 6
        } else if (containerWidth >= 1520) {
          colNum = 5
        } else if (containerWidth >= 1210) {
          colNum = 4
        } else if (containerWidth >= 900) {
          colNum = 3
        } else if (containerWidth >= 590) {
          colNum = 2
        } else {
          colNum = 1
        }
        // 每一列的宽度为总宽度减去左右padding减去每列之间的间距减去滚动条宽度，除以列数量
        this.itemWidth = ((containerWidth - (parseFloat(this.colGap) * (colNum - 1))) / colNum) + 'px'
      }
    },
    setVh() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    download() {
      const os = this.getType()
      const item = this.downList.find((v) => v.type === os)
      if (item) {
        this.clickHandle(item)
      }
    },
    clickHandle(item) {
      if (item.value === 'appstore') {
        window.open(item.url)
      } else {
        const fileName = item.url.substring(item.url.lastIndexOf('/') + 1)
        this.downloadFile(item.url, fileName)
      }
    },
    toApp(item) {
      if (this.isMobile) {
        if (this.isIOS) {
          this.redirectToOIA(item)
        } else {
          this.callApp(item)
        }
      } else {
        Message({
          type: 'warning',
          message: this.$t('PostList.lang_postmsg'),
          customClass: 'warning-toast'
        })
      }
    },
    // 下载文件
    downFile(item) {
      this.downloadFile(item.url, item.name)
    },
    currentTimeChange(item) {
      if (!this.$refs.audioEl) {
        this.currentUrl = item.url
        this.currentAudio = item
      }
      // 如果原来已经有在播放了，就暂停
      if (
        this.currentAudio &&
        this.currentAudio.url !== item.url &&
        this.currentAudio.isPlaying
      ) {
        this.$set(this.currentAudio, 'isPlaying', false)
      }
      this.currentUrl = item.url
      this.currentAudio = item
      this.$nextTick(() => {
        this.$refs.audioEl.currentTime = item.currentTime
        this.$refs.audioEl.play()
      })
    },
    handlePlay() {
      this.$set(this.currentAudio, 'isPlaying', true)
    },
    handlePause() {
      this.$set(this.currentAudio, 'isPlaying', false)
    },
    handleTimeupdate() {
      this.$set(
        this.currentAudio,
        'currentTime',
        this.$refs.audioEl.currentTime
      )
    },
    handleEnded() {
      this.$set(this.currentAudio, 'currentTime', 0)
      this.$set(this.currentAudio, 'isPlaying', false)
    },
    exitFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen()
      }
    },
    handleError(e) {
      console.log('handleError', e)
      this.$set(this.currentAudio, 'isPlaying', false)
      this.$toast.fail(this.$t('PostList.failToPlay'))
    },
    togglePlayAudio(item) {
      // 如果原来已经有在播放了，就暂停
      if (
        this.currentAudio &&
        this.currentAudio.url !== item.url &&
        this.currentAudio.isPlaying
      ) {
        this.$set(this.currentAudio, 'isPlaying', false)
      }
      this.currentUrl = item.url
      this.currentAudio = item
      this.$nextTick(() => {
        if (item.currentTime) {
          this.$refs.audioEl.currentTime = item.currentTime || 0
        }
        if (item.isPlaying) {
          this.$refs.audioEl.pause()
        } else {
          this.$refs.audioEl.play()
        }
      })
    },
    showVideo(item) {
      this.currentVideo = item
      this.videoShow = true
      const options = {
        id: 'videoPlayer',
        autoplay: true,
        url: this.currentVideo.url,
        lang: this.$i18n.locale,
        marginControls: true,
        ignores: ['playbackrate', 'fullscreen'],
        width: '100%',
        height: 'auto'
        // videoFillMode: 'fill',
      }
      if (this.bigScreen) {
        options.ignores = ['playbackrate', 'cssfullscreen']
      }
      this.$nextTick(() => {
        const player = new Player(options)
        console.log(
          '🚀 ~ file: PostList.vue:1759 ~ this.$nextTick ~ player:',
          player
        )
      })
    },
    redirectToOIA(item) {
      if (location.hostname.includes('test')) {
        window.location.href =
          'mosapptestscheme://https://test.mos.me/go?id=' + item.linkInfo
      } else {
        window.location.href =
          'mosappscheme://https://mos.me/go?id=' + item.linkInfo
      }
    },
    callApp(item) {
      const CallApp = require('callapp-lib')
      const callAppOption = {
        scheme: {
          protocol: 'test-mos',
          host: 'me'
        },
        appstore: 'https://apps.apple.com/cn/app/mosapp/id6446265600',
        yingyongbao: 'https://dl.mosapp.app',
        fallback: 'https://dl.mosapp.app',
        timeout: 3000
      }
      // 正式环境
      if (!location.hostname.includes('test')) {
        callAppOption.scheme.protocol = 'mos'
        callAppOption.timeout = 5000
      }
      const that = this
      const callLib = new CallApp(callAppOption)
      callLib.open({
        path: 'go',
        param: { id: item.linkInfo },
        callback: function () {
          that.download()
        }
      })
    },
    downloadFile(url, name) {
      // const res = await fetch(url)
      // const blob = await res.blob()
      // const linkHref = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.download = name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      // window.URL.revokeObjectURL(linkHref)
    },
    getType() {
      if (this.isMobile) {
        if (this.isAndroid) return 'Android'
        if (this.isIOS) return 'IOS'
      } else {
        if (this.isMacOs) return 'MacOS'
        if (this.isWindows) return 'Windows'
      }
    }
  }
}
</script>
<style lang="less">
.warning-toast {
  z-index: 9999 !important;
}

.van-toast__text {
  word-break: break-word;
}
.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-y-center {
  display: flex;
  align-items: center;
}

.flex-x-sb {
  display: flex;
  justify-content: space-between;
}
.pointer {
  cursor: pointer;
}
.overflow-ellipsis-1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
}

.overflow-ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

/* 视频播放器进度条 */
.xgplayer {
  .xgplayer-progress-cache {
    background: rgba(255, 255, 255, 0.3) !important;
  }
  .xgplayer-progress-played {
    background: #fff !important;
  }
  .xgplayer-progress-btn {
    background: rgba(255, 255, 255, 0.3) !important;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.6) !important;
  }
  xg-video-container.xg-video-container {
    bottom: 65px !important;
  }
}

/* 错误图片 */
.el-image .image-error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
}

.el-image .image-error .el-icon-picture-outline {
  font-size: 30px;
}
</style>
<style lang="less" scoped>
.container {
  padding-top: 80px;
  padding-bottom: 80px;
}
.container-title {
  color: #000;
  /* H1/medium */
  font-family: Roboto;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 46px; /* 121.053% */
  margin-bottom: 32px;
}

.msg-box {
  padding: 50px 70px 30px;
  text-align: center;
}

.msg-box img {
  display: block;
  width: 20.53vw;
  height: 20.53vw;
  border-radius: 10.67vw;
  margin: 0 auto;
  background: #ccc;
}

.msg-box .name {
  padding: 0 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 4.53vw;
  color: #000;
  margin: 3.73vw 0 1.6vw 0;
  font-weight: bold;
}

.msg-box .msg {
  font-size: 4vw;
  color: rgba(60, 60, 67, 0.6);
  margin-bottom: 3.73vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.msg-box .text {
  display: -webkit-box;
  word-break: break-word;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-size: 4vw;
  color: #3c3c43;
}

.msg-box .button {
  margin-top: 38px;
  border-radius: 100px;
  background: #067eff;
  color: #fff;
  cursor: pointer;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

.msg-box .button:active {
  opacity: 0.8;
}

.image-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2px;
}

.image-container .item {
  width: 100%;
  height: 100%;
  max-height: 420px;
  overflow: hidden;
  /* 维持宽高比为1:1 */
  aspect-ratio: 1 / 1;
}

.image-container .item .el-image,
.image-container .item .el-image img {
  width: 100%;
  height: 100%;
}

.image-type-1 .item .el-image {
  border-radius: 20px 20px 0 0;
}

.image-type-2 .item:nth-child(1) .el-image,
.image-type-3 .item:nth-child(1) .el-image,
.image-type-4 .item:nth-child(1) .el-image,
.image-type-5 .item:nth-child(1) .el-image,
.image-type-6 .item:nth-child(1) .el-image,
.image-type-7 .item:nth-child(1) .el-image,
.image-type-8 .item:nth-child(1) .el-image,
.image-type-9 .item:nth-child(1) .el-image {
  border-radius: 20px 0 0 0;
}

.image-type-2 .item:nth-child(2) .el-image,
.image-type-3 .item:nth-child(2) .el-image,
.image-type-4 .item:nth-child(2) .el-image,
.image-type-5 .item:nth-child(2) .el-image,
.image-type-6 .item:nth-child(3) .el-image,
.image-type-7 .item:nth-child(2) .el-image,
.image-type-8 .item:nth-child(2) .el-image,
.image-type-9 .item:nth-child(3) .el-image {
  border-radius: 0 20px 0 0;
}

.image-type-1 .item {
  grid-column: span 6;
}

.image-type-2 .item,
.image-type-4 .item,
.image-type-5 .item:nth-child(1),
.image-type-5 .item:nth-child(2),
.image-type-7 .item,
.image-type-8 .item:nth-child(1),
.image-type-8 .item:nth-child(2) {
  grid-column: span 3;
}

.image-type-3 .item:nth-child(1) {
  grid-column: span 4;
  grid-row: span 2;
}

.image-type-3 .item:nth-child(2),
.image-type-3 .item:nth-child(3),
.image-type-5 .item,
.image-type-6 .item,
.image-type-7 .item:nth-child(5),
.image-type-7 .item:nth-child(6),
.image-type-7 .item:nth-child(7),
.image-type-8 .item,
.image-type-9 .item {
  grid-column: span 2;
}

/* 图片预览的弹窗 */
.top-content {
  position: fixed;
  padding: 5px 16px;
  top: 0;
  height: 44px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 17px;
  background-color: rgba(0, 0, 0, 1);
}

.close-icon {
  cursor: pointer;
}

.down-icon {
  visibility: hidden;
}

.bottom-tips {
  position: fixed;
  bottom: 0;
}

/* 语音 */
.audio-container {
  margin-top: 16px;
}

.audio-container .audio-item {
  position: relative;
  margin: 23px 15px 0;
}

.audio-bg {
  width: 100%;
  height: 160.554px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  filter: blur(5px);
}

.audio-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
}

.audio-avatar {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.duration {
  color: #fff;
  padding: 2.33px 6.98px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 9.23px;
  left: 8.93px;
}

.duration-text {
  font-size: 11.634px;
  letter-spacing: 0.465px;
  margin-right: 2.33px;
}

.video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.progress-bar {
  position: absolute;
  bottom: 10px;
  left: 28px;
  right: 28px;
  :deep(.el-slider) {
    .el-slider__runway {
      height: 2px;
      margin: 0;
      background-color: rgba(255, 255, 255, 0.3);
    }
    .el-slider__bar {
      height: 2px;
      background-color: #fff;
    }
    .el-slider__button-wrapper {
      display: none;
    }
  }
}

// 视频
.video-img {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-content {
  width: 100%;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
  height: 420px;
}

.video-img .el-image {
  width: 100%;
  height: 100%;
  border-radius: 20px 20px 0 0;
  height: 420px;
}

.video-img .video-play {
  width: 76px;
  height: 76px;
}

/* 文件 */
.file-container {
  background-color: #f2f2f7;
  border-radius: 23.269px;
  margin: 20px 15px 0;
}

.file-item {
  padding: 25px 16px 0;
  cursor: pointer;
}

.file-item + .file-item {
  margin-top: 9px;
}

.file-item:last-child {
  padding-bottom: 25px;
}

.file-icon {
  width: 42px;
  height: 42px;
  margin-right: 10px;
}

.file-name {
  color: #111;
  font-size: 16px;
  font-weight: 500;
  word-break: break-word;
}

.file-size {
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
}

.red-packet-container {
  padding: 20px 16px 0;
}

.red-packet-container .red-packet-box {
  border-radius: 20px;
  background: linear-gradient(270deg, #F83E3D 0%, #DD2F30 100%);
  padding: 24px 16px;
  display: flex;
  align-items: center;
  cursor: default;
  height: 150px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}

.red-packet-container .red-packet-box .red-packet-icon-bg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.red-packet-container .red-packet-box .red-packet-icon {
  width: 42px;
  height: 42px;
  user-select: none;
  position: absolute;
  left: 25px;
  z-index: 2;
}

.red-packet-container .red-packet-box .red-packet-text {
  color: #FFDAAE;
  font-size: 18px;
  font-weight: 500;
  margin-left: 101px;
  letter-spacing: -0.113px;
}

/* 文字 */
.text-container {
  overflow: hidden;
  margin-top: 16px;
  padding: 0 15px;
  font-size: 17.452px;
  position: relative;
}

.text-container .text {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 14;
  position: relative;
  line-height: 1.5;
  /* max-height: 21em; */
  white-space: pre-wrap;
  transition: 0.3s max-height;
  word-break: break-word;
  user-select: text;
}

.text-container .show-more {
  background-color: #f7f7fa;
}

.show-more {
  position: absolute;
  right: 15px;
  bottom: 0;
  background-color: #fff;
  display: flex;
  line-height: 1.5;
}

.more-dot {
  margin-right: 5px;
}

.show-more-btn {
  color: #067eff;
  cursor: pointer;
  user-select: none;
}

.tag-list {
  padding: 0 20px 0 12px;
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
}

.tag-list .tag-item {
  box-sizing: border-box;
  padding: 3px 10px;
  color: #067eff;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 128.571% */
  border-radius: 40px;
  background-color: rgba(6, 126, 255, 0.08);
  margin: 0 0 8px 8px;
}

/* 地理信息 */
.address-content {
  padding: 0 20px;
  margin-top: 16px;
}

.address-text {
  color: rgba(0, 0, 0, 0.4);
  font-size: 13.961px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.942px;
  letter-spacing: -0.475px;
  margin-left: 4.65px;
}

/* 表态区 */
.action-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  padding: 0 15px;
  font-size: 12px;
}

.action-content .action-content-left {
  display: flex;
  align-items: center;
  padding: 6px 14px 6px;
  border-radius: 100px;
  background: #ececf2;
}

.action-content .action-content-right {
  margin-left: auto;
  opacity: 0.4033;
  display: flex;
  align-items: center;
}

.action-content-right .view-count {
  display: flex;
  align-items: center;
}

.action-content-right .view-count .count {
  margin-left: 6px;
  white-space: nowrap;
}

.action-content .icon {
  width: 18px;
  height: 18px;
}

.action-content .icon + .icon {
  margin-left: 6px;
}

.interaction-count {
  font-weight: 900;
  margin-left: 10px;
  white-space: nowrap;
}

/* 评论区 */
.comment {
  margin-top: 16px;
  padding: 0 15px 0 25px;
  justify-content: space-between;
}

.comment .avatar-content .el-avatar {
  margin-left: -10px;
  border: 2px solid #fff;
}

.comment-text {
  color: #39f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.76px;
  margin-left: 12px;
  cursor: pointer;
}

.comment .create-time {
  opacity: 0.4033;
  margin-left: auto;
}

.list-container {
  padding: 0;
  width: 100%;
  box-sizing: border-box;
}

.main-item {
  position: relative;
  background-color: #f7f7fa;
  border-radius: 20px;
  padding: 0 0 20px;
  overflow: hidden;
  margin-bottom: 16px;
}

.list-container:not(.big-screen) .main-item {
  width: 100% !important;
}

.list-container.big-screen .main-item {
  min-width: 280px;
  background-color: #f7f7fa;
}

.list-container.big-screen .main-item .file-container {
  background-color: #fff;
}

.list-container.big-screen .main-item .show-more {
  background-color: #f7f7fa;
}

.list-container.big-screen .main-item .action-content .action-content-left {
  background-color: #ECECF2;
}

.main-item .forward-icon {
  color: #F5F5F5;
}

.list-container.big-screen .main-item .forward-icon {
  color: #ECECF2;
}

.main-item .action-content-right .create-time {
  margin-left: 6px;
  white-space: nowrap;
}

.text-container .text {
  -webkit-line-clamp: unset;
  line-clamp: unset;
  font-size: 15px;
}

.comment-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.comment-container .van-list {
  flex: 1;
  overflow: auto;
}

.top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
}

.comment-popup-wrapper:not(.big) .top-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.comment-item {
  padding: 16px;
  display: flex;
}

.comment-name {
  color: rgba(0, 0, 0, 0.40);
  font-size: 12px;
}

.comment-time {
  color: rgba(0, 0, 0, 0.40);
  font-size: 12px;
  margin-top: 3px;
}

.user-tag {
  font-size: 11px;
  height: 18px;
  white-space: nowrap;
  line-height: 18px;
  text-align: center;
  border-radius: 20px;
  margin-left: 8px;
  padding: 0 4px;
}

.user-tag.creator-tag {
  color: #067EFF;
  background-color: #067EFF33;
}

.user-tag.admin-tag {
  color: #e6a23c;
  background-color: #fdf6ec;
}

.comment-content {
  margin-top: 4px;
  text-align: justify;
  overflow: hidden;
  position: relative;
}

.comment-content .reply-name {
  color: #067EFF;
  margin-right: 5px;
}

.comment-content .text {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  line-height: 20px;
  position: relative;
  white-space: pre-wrap;
  transition: .3s height;
  word-break: break-word;
  user-select: text;
  font-size: 14px;
}

.show-more {
  position: absolute;
  right: 15px;
  bottom: 0;
  background: #fff;
  display: flex;
  line-height: 20px;
}

.show-more.is-expend {
  position: relative;
  right: 0;
  float: right;
}

.show-more.comment-more {
  right: 0;
  font-size: 14px;
}

.more-dot {
  margin-right: 5px;
}

.show-more-btn {
  font-size: 15px;
}

.comment-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.comment-reply {
  color: rgba(0, 0, 0, 0.40);
  font-size: 12px;
  position: relative;
  padding-left: 35px;
}

.comment-reply::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 30px;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.40);
}

.comment-like {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.like-count {
  color: rgba(0, 0, 0, 0.40);
  margin-left: 6px;
  white-space: nowrap;
}

.comment-reply-loading {
  color: rgba(0, 0, 0, 0.40);
  font-size: 12px;
}

.video-popup {
  background-color: #000000;
  height: 100%;
  position: relative;
}

.video-header {
  color: #fff;
  padding: 0 20px;
  height: 60px;
  display: flex;
  align-items: center;
}

.video-popup #videoPlayer {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 60px;
  bottom: 60px;
  width: 100%;
}

.download-popover {
  width: 100px;
  height: 50px;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
}

.loading-tip {
  padding: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.40);
  font-size: 12px;
}

.video-popup-wrapper {
  background-color: transparent;
  height: 100%;
}

.video-popup-wrapper.big {
  width: 50%;
  height: 50%;
  border-radius: 5px;
}

.video-popup-wrapper.big .video-header {
  flex-direction: row-reverse;
}

.video-popup-wrapper.big .video-popup #videoPlayer {
  bottom: 5px;
}

.comment-popup-wrapper {
  height: 70%;
}

.comment-popup-wrapper.big {
  width: 50%;
  height: 50%;
  border-radius: 5px;
}
</style>
