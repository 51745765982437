<template lang="">
  <div>
    <template v-if="$i18n.locale === 'en'">
      <div>
        <div class="banner">
          <div class="container">
            <el-row :gutter="20" class="content-head">
              <el-col :span="24">
                <router-link class="linkTo" to="/careers"><p style="margin-top: 100px; color: #3370FF">Career/</p></router-link>
                <h1>Frontend Architect</h1>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="container" style="margin-top: 30px;">
          <ApplyModal />
          <el-row :gutter="24" style="margin-top: 40px">
            <el-col :span="18">
              <div class="career-detail">
                <h1>Job Description</h1>
                <p>MosApp is an instant messaging application that uses end-to-end encryption technology. It focuses on information security and protecting user privacy. The encrypted chat server does not save any information, and anyone outside the conversation cannot read or listen to it. You can safely chat and make audio and video calls with your friends and family. MosApp features include encrypted one-on-one and group chats, channel subscriptions, group chats with up to 10,000 people, burn after reading messages, two-way deletion, read receipts, and app lock screens.</p>
                <h1>Benefits</h1>
                <p>Good pay | Perfect attendance incentive | Employee referral incentive | NSSF | Opportunities for promotion | Large teamwork | Modern and comfortable office | Best office location | Public holidays follow labor law of the kingdom (100%) | Annual leave, maternity leave (3 months), paternity, special leave, sick leave, marriage leave, mourning leave...etc.</p>
                <h1>Qualification Requirement</h1>
                <ul class="list-style">
                  <li><p>Bachelor's degree or higher in computer-related majors, with at least 5 years of front-end development experience and 2 years of experience as an architect. Priority will be given to candidates with experience in top-tier internet companies, and exceptional candidates may have relaxed educational requirements.</p></li>
                  <li><p>Proficiency in the underlying frameworks such as Vue or React, knowledge of technologies like Chromium, V8, Node.js, and the ability to perform performance optimization. Familiarity with various common design patterns and a deep understanding of code structuring and optimization.</p></li>
                  <li><p>Familiarity with front-end engineering, componentization, and modularization, knowledge of micro-frontends architecture, and proficiency in tools like Webpack. Experience in developing tool plugins is a plus.</p></li>
                  <li><p>Strong interest and learning ability in front-end technologies, keeping up with the latest trends, and incorporating suitable new technologies.</p></li>
                  <li><p>Possess team spirit and a sense of responsibility, self-driven, positive work attitude, strong sense of responsibility, and good communication and teamwork skills.</p></li>
                  <li><p>Bonus points: Prior knowledge of cross-platform development frameworks like React Native, Flutter; knowledge of WebGL development is preferred; experience leading low-code or scaffolding projects is advantageous.</p></li>
                  <li><p>Bonus points: Familiarity with iOS and Android development frameworks, solid foundation in Swift and Kotlin, and a deep understanding of memory optimization, IO optimization, and CPU optimization.</p></li>
                  <li><p>Prior experience in enterprise-wide information technology construction and data governance is a plus; experience in information technology for the construction industry, smart cities, or smart construction sites is preferred.</p></li>
                  <li><p>Strong sense of responsibility, ability to handle pressure, positive and proactive attitude towards learning.</p></li>
                </ul>
                <h1>Responsibility</h1>
                <ul class="list-style">
                  <li><p>Responsible for the front-end technical architecture design of software projects to support the development team in project delivery.</p></li>
                  <li><p>Responsible for the construction of technical foundation platforms and the design of core technical components to improve the development team's productivity.</p></li>
                  <li><p>Responsible for tackling challenging technical issues; able to lead or guide the development team in solving difficult problems.</p></li>
                  <li><p>Responsible for code reviews within the development team to ensure the quality of deliverables.</p></li>
                  <li><p>Responsible for the technical growth of the development team, ensuring continuous skill development through various methods.</p></li>
                  <li><p>Responsible for technology selection within the company.</p></li>
                </ul>
                <h1>Contact Info</h1>
                <p>CONTACT NAME : Ms.Camila</p>
                <!-- <p>ADDRESS : 111 SOMERSET ROAD #08-05, 111 SOMERSET SINGAPORE (238164)</p> -->
                <p>EMAIL : mosapp.app@gmail.com</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <h1 style="font-size:20px;">Job Details</h1>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">Employment Type</p>
                  <p>Full-time</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">Vacancy</p>
                  <p>15 Positions</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">Deadline</p>
                  <p>31 Nov 2023</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </template>
    <template v-if="$i18n.locale === 'zh'">
      <div>
        <div class="banner">
          <div class="container">
            <el-row :gutter="20" class="content-head">
              <el-col :span="24">
                <router-link class="linkTo" to="/careers"><p style="margin-top: 100px; color: #3370FF">职业/</p></router-link>
                <h1>前端架构师</h1>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="container" style="margin-top: 30px;">
          <ApplyModal />
          <el-row :gutter="24" style="margin-top: 40px">
            <el-col :span="18">
              <div class="career-detail">
                <h1>岗位描述</h1>
                <p>MosApp 是一款使用端到端加密技术的即时通讯应用。它专注于信息安全和保护用户隐私。加密聊天服务器不保存任何信息，对话外的任何人都无法阅读或收听。您可以安全地与朋友和家人进行聊天，并进行音频和视频通话。MosApp 的功能包括加密的一对一和群组聊天，频道订阅，最多可容纳 10,000 人的群组聊天，阅后即焚消息，双向删除，已读回执以及应用程序锁定屏幕。</p>
                <h1>福利待遇</h1>
                <p>Good pay | Perfect attendance incentive | Employee referral incentive | NSSF | Opportunities for promotion | Large teamwork | Modern and comfortable office | Best office location | Public holidays follow labor law of the kingdom (100%) | Annual leave, maternity leave (3 months), paternity, special leave, sick leave, marriage leave, mourning leave...etc.</p>
                <h1>资格要求</h1>
                <ul class="list-style">
                  <li><p>第一学历统招一本及以上，计算机相关专业，5年以上前端开发经验，2年以上架构师经验，具有一线互联网大厂架构经验者优先，特别优秀可放宽学历要求；</p></li>
                  <li><p>研究过Vue或React等框架底层，了解Chromuim. V8. Node.js等技术领域，具备一定性能调优能力；熟悉各种常用设计模式，对代码的分层与优化等设计方法有深刻的理解；</p></li>
                  <li><p>熟悉前端工程化. 组件化. 模块化，了解微前端架构，熟练Webpack等工具，有过工具插件的开发经验优先；</p></li>
                  <li><p>对前端技术有强力的兴趣和学习能力，紧跟前端发展方向，引入合适的新技术；</p></li>
                  <li><p>具有团队精神和责任感，自驱力强，工作态度积极，责任心强，良好的沟通与团队配合能力；</p></li>
                  <li><p>加分项：了解跨平台开发框架ReactNative. Flutter优先；了解WebGL开发的优先；主导过低代码. 研发脚手架项目者优先；</p></li>
                  <li><p>加分项：了解iOS. Android开发框架，Swift. Kotlin基础知识牢固，对内存优化. IO优化. CPU优化有深入理解；</p></li>
                  <li><p>具有企业整体信息化建设. 数据治理经验优先；具有建筑施工行业信息化. 智慧城市. 智慧工地等经验者优先。</p></li>
                  <li><p>责任心强，有较强的抗压能力，积极向上，热爱学习。</p></li>
                </ul>
                <h1>职责</h1>
                <ul class="list-style">
                  <li><p>负责软件项目的前端技术架构设计，支撑研发团队完成项目交付；</p></li>
                  <li><p>负责技术基础平台建设，负责技术核心组件设计，提升研发团队产出效能；</p></li>
                  <li><p>负责重难点技术点攻关；能够带领或者指导研发团队解决疑难问题；</p></li>
                  <li><p>负责研发团队代码评审，保证研发团队产出的质量；</p></li>
                  <li><p>负责研发团队的技术成长，通过各种方法保持研发团队的技术成长性；</p></li>
                  <li><p>负责公司技术选型；</p></li>
                </ul>
                <h1>联系信息</h1>
                <p>联系人姓名 : Ms.Camila</p>
                <!-- <p>地址 : 111 SOMERSET ROAD #08-05, 111 SOMERSET SINGAPORE (238164)</p> -->
                <p>电子邮件 : mosapp.app@gmail.com</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <h1 style="font-size:20px;">职位详情</h1>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">雇佣类型</p>
                  <p>Full-time</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">空缺职位</p>
                  <p>15 Positions</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">截止日期</p>
                  <p>31 Nov 2023</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import ApplyModal from './ApplyModal.vue'

export default {
  components: {
    ApplyModal
  },
  data() {
    return { }
  }
}
</script>
<style lang="less" scoped>
.banner {
  position: relative;
  background-color: #E7EEFF99;
  background-position: center;
  height: auto;
  .container {
    color: #ffffff;
    position: relative;
    .content-head {
      padding-bottom: 40px;
      h1 {
        color: #1F1F1F;
        font-size: 50px;
        font-weight: 700;
      }
      p {
        font-size: 20px;
        font-weight: 400px;
      }
    }
  }
}
.career-detail {
  h1 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  p {
    font-size: 17px;
    margin-bottom: 30px;
  }
}
ul.list-style {
  p {
    margin: 0;
  }
  margin-left: 35px;
  margin-bottom: 30px;
  li {
    list-style-type: inherit;
  }
}
</style>
