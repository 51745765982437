import axios from 'axios'

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000 // Set the desired timeout value
})

export function makeApiRequest(config) {
  if (!config.headers) {
    config.headers = {}
  }
  config.headers.lang = navigator.language || 'en-US'
  return api.request(config)
    .then(response => response.data)
    .catch(error => {
      // Handle the error if needed
      throw error
    })
}
