<template lang="">
  <div>
    <template v-if="$i18n.locale === 'en'">
      <div>
        <div class="banner">
          <div class="container">
            <el-row :gutter="20" class="content-head">
              <el-col :span="24">
                <router-link class="linkTo" to="/careers"><p style="margin-top: 100px; color: #3370FF">Career/</p></router-link>
                <h1>UI & UX Designer</h1>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="container" style="margin-top: 30px;">
          <ApplyModal />
          <el-row :gutter="24" style="margin-top: 40px">
            <el-col :span="18">
              <div class="career-detail">
                <h1>Job Description</h1>
                <p>MosApp is an instant messaging application that uses end-to-end encryption technology. It focuses on information security and protecting user privacy. The encrypted chat server does not save any information, and anyone outside the conversation cannot read or listen to it. You can safely chat and make audio and video calls with your friends and family. MosApp features include encrypted one-on-one and group chats, channel subscriptions, group chats with up to 10,000 people, burn after reading messages, two-way deletion, read receipts, and app lock screens.</p>
                <h1>Benefits</h1>
                <p>Good pay | Perfect attendance incentive | Employee referral incentive | NSSF | Opportunities for promotion | Large teamwork | Modern and comfortable office | Best office location | Public holidays follow labor law of the kingdom (100%) | Annual leave, maternity leave (3 months), paternity, special leave, sick leave, marriage leave, mourning leave...etc.</p>
                <h1>Qualification Requirement</h1>
                <ul class="list-style">
                  <li><p>3 years or more of experience in product UX design.</p></li>
                  <li><p>Background in industrial design, digital media arts, or other related design fields, with a bachelor's degree or higher.</p></li>
                  <li><p>Proficiency in commonly used design tools such as Axure, Photoshop, Sketch, Invision, etc.</p></li>
                  <li><p>Deep insights into interaction design and design research in the internet industry, with a certain ability for research and innovation.</p></li>
                  <li><p>Strong drive and execution in design, excellent communication and teamwork skills, proactiveness, attention to detail, and strong coordination abilities.</p></li>
                </ul>
                <h1>Responsibility</h1>
                <ul class="list-style">
                  <li><p>Conduct in-depth analysis of business requirements, focusing on core needs while considering product application scenarios. Collaborate with product managers through user research, data analysis, and competitor research to formulate strategies and plans for optimizing the product experience. Output process frameworks and low/high-fidelity interactive prototypes to ensure a smooth and user-friendly product.</p></li>
                  <li><p>Participate in user research and keep track of industry trends to understand genuine user needs and identify points of value for user experience design.</p></li>
                  <li><p>Lead user experience and UI design, continuously reviewing product interactions and UI details to create a closed loop of improvement.</p></li>
                  <li><p>Gather user data and feedback to provide a basis for continuous optimization of product UI and user experience (UE).</p></li>
                  <li><p>Research and learn about new design standards, trends, software, and tools in both domestic and international contexts.</p></li>
                </ul>
                <h1>Contact Info</h1>
                <p>CONTACT NAME : Ms.Camila</p>
                <!-- <p>ADDRESS : 111 SOMERSET ROAD #08-05, 111 SOMERSET SINGAPORE (238164)</p> -->
                <p>EMAIL : mosapp.app@gmail.com</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <h1 style="font-size:20px;">Job Details</h1>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">Employment Type</p>
                  <p>Full-time</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">Vacancy</p>
                  <p>15 Positions</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">Deadline</p>
                  <p>31 Nov 2023</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </template>
    <template v-if="$i18n.locale === 'zh'">
      <div>
        <div class="banner">
          <div class="container">
            <el-row :gutter="20" class="content-head">
              <el-col :span="24">
                <router-link class="linkTo" to="/careers"><p style="margin-top: 100px; color: #3370FF">职业/</p></router-link>
                <h1>交互设计师/UX</h1>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="container" style="margin-top: 30px;">
          <ApplyModal />
          <el-row :gutter="24" style="margin-top: 40px">
            <el-col :span="18">
              <div class="career-detail">
                <h1>岗位描述</h1>
                <p>MosApp 是一款使用端到端加密技术的即时通讯应用。它专注于信息安全和保护用户隐私。加密聊天服务器不保存任何信息，对话外的任何人都无法阅读或收听。您可以安全地与朋友和家人进行聊天，并进行音频和视频通话。MosApp 的功能包括加密的一对一和群组聊天，频道订阅，最多可容纳 10,000 人的群组聊天，阅后即焚消息，双向删除，已读回执以及应用程序锁定屏幕。</p>
                <h1>福利待遇</h1>
                <p>Good pay | Perfect attendance incentive | Employee referral incentive | NSSF | Opportunities for promotion | Large teamwork | Modern and comfortable office | Best office location | Public holidays follow labor law of the kingdom (100%) | Annual leave, maternity leave (3 months), paternity, special leave, sick leave, marriage leave, mourning leave...etc.</p>
                <h1>资格要求</h1>
                <ul class="list-style">
                  <li><p>3 年或以上产品 UX 设计经历；</p></li>
                  <li><p>工业设计.数字媒体美术.等设计类相关专业，本科及以上学历；</p></li>
                  <li><p>精通使用常见的设计工具如：Axure.Photoshop.Sketch.Invision等；</p></li>
                  <li><p>对互联网领域的交互设计和设计研究方面的理论及实践拥有深刻见解，具备一定的研究创新能力；</p></li>
                  <li><p>具备较强的设计推动力和执行力，优良的沟通.设计表达.团队协作能力，主动积极，踏实细心，协调能力强。</p></li>
                </ul>
                <h1>职责</h1>
                <ul class="list-style">
                  <li><p>深入分析业务需求，以产品应用场景为依归，聚焦核心需求，通过用研.数据.竞品分析等配合产品经理.策划制定产品体验优化策略和方案，输出流程.框架及低高保真交互原型，确保产品流畅.易用；</p></li>
                  <li><p>参与用户研究.关注行业动态，了解用户真实需求，寻找用户体验设计价值点；</p></li>
                  <li><p>主导用户体验设计设计和ui设计，并持续走查产品交互与ui细节，形成闭环；</p></li>
                  <li><p>收集用户数据的变化和用户反馈，为持续优化产品UI和UE提供依据；</p></li>
                  <li><p>调研和学习国内外新的设计规范和趋势.设计软件和工具。</p></li>
                </ul>
                <h1>联系信息</h1>
                <p>联系人姓名 : Ms.Camila</p>
                <!-- <p>地址 : 111 SOMERSET ROAD #08-05, 111 SOMERSET SINGAPORE (238164)</p> -->
                <p>电子邮件 : mosapp.app@gmail.com</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <h1 style="font-size:20px;">职位详情</h1>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">雇佣类型</p>
                  <p>Full-time</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">空缺职位</p>
                  <p>15 Positions</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">截止日期</p>
                  <p>31 Nov 2023</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import ApplyModal from './ApplyModal.vue'

export default {
  components: {
    ApplyModal
  },
  data() {
    return { }
  }
}
</script>
<style lang="less" scoped>
.banner {
  position: relative;
  background-color: #E7EEFF99;
  background-position: center;
  height: auto;
  .container {
    color: #ffffff;
    position: relative;
    .content-head {
      padding-bottom: 40px;
      h1 {
        color: #1F1F1F;
        font-size: 50px;
        font-weight: 700;
      }
      p {
        font-size: 20px;
        font-weight: 400px;
      }
    }
  }
}
.career-detail {
  h1 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  p {
    font-size: 17px;
    margin-bottom: 30px;
  }
}
ul.list-style {
  p {
    margin: 0;
  }
  margin-left: 35px;
  margin-bottom: 30px;
  li {
    list-style-type: inherit;
  }
}
</style>
